import {Box, Button, Grid, Typography} from "@mui/material";
import React, {useContext} from "react";
import {ThemeContext} from "../contexts/ThemeContext";
import {allMattSelvansMusicalProjects, buyEverthingStartsBandcamp, campingInTheSkiesWeb} from "../config";
import {LanguageContext} from "../contexts/LocalizationContext/languages/LanguageContext";
import {LayoutContext} from "../contexts/LayoutContext/LayoutContext";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

export const SupportIndependentMusicBanner = () => {

    const { dictionary } = useContext(LanguageContext)
    const { isMobile, isTablet } = useContext(LayoutContext)
    const { theme } = useContext(ThemeContext)

    return(
        <Grid container direction="column" alignItems="center" justifyContent="center" height="100%" width="100%" spacing={2}>
            <Grid item textAlign={"center"}>
                <Typography variant={isMobile ? "body1" : "h3"} fontWeight="bold" color={theme.palette.text.primary}>
                    {dictionary.supportIndependentMusic}
                </Typography>
            </Grid>
            <Grid item textAlign={"center"}>
                <Button onClick={() => {window.open(buyEverthingStartsBandcamp, '_blank');}} variant="contained">
                    <Typography  fontWeight="bold" sx={{ fontSize: isMobile ? 13 : 25}}>
                        {dictionary.purchaseNow}
                    </Typography>
                </Button>
            </Grid>
        </Grid>
    )
}

// export const OtherMusicalProjectsBanner = () => {
//
//     // const { dictionary } = useContext(LanguageContext)
//     const { isMobile, isTablet } = useContext(LayoutContext)
//     const { theme } = useContext(ThemeContext)
//
//     return(
//         <Grid container direction="row" alignItems="center" justifyContent="center" height="100%" width="100%">
//             <Grid item textAlign={"center"} sx={{ marginTop: ( isMobile || isTablet ) ? -3 : 0}}>
//                 <Typography variant={isMobile ? "body1" : "h3"} fontWeight="bold" color={theme.palette.text.primary}>
//                     Matt Selvan’s Musical Projects
//                 </Typography>
//             </Grid>
//             <Grid item container direction="row" alignItems="center" justifyContent="space-around" textAlign={"center"} lg={12} sm={12} xs={12} sx={{ marginTop: ( isMobile || isTablet ) ? -3 : 0}}>
//                     <Box
//                         sx={{
//                             width: '100%',
//                             display: 'flex',
//                             justifyContent: 'center',
//                             alignItems: 'center',
//                             position: 'absolute',
//                         }}
//                         gap={10}
//                     >
//                 { allMattSelvansMusicalProjects.map(( project, i) => (
//                         <Typography variant={( isMobile || isTablet ) ? "body1" : "h4"} fontWeight="bold" color={theme.palette.text.primary} key={i}>
//                             {project.link ? (
//                                 <a href={project.link} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit', display: 'flex', alignItems: 'center' }}>
//                                     <OpenInNewIcon fontSize="inherit"/>
//                                     {project.name}
//                                 </a>
//                             ) : (
//                                 <div style={{ display: 'flex', alignItems: 'center' }}>
//                                     {project.name}
//                                 </div>
//                             )}
//                         </Typography>
//                 ))}
//                     </Box>
//             </Grid>
//         </Grid>
//     )
// }