import React from 'react';
import {Grid, Typography} from '@mui/material';
import {allMattSelvansMusicalProjects} from '../displayConfig';
import Carousel from 'react-material-ui-carousel';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useContext } from 'react';
import { ThemeContext } from '../contexts/ThemeContext';
import { LayoutContext } from '../contexts/LayoutContext/LayoutContext';
import {globalLogoColorLetterS} from "../styles/muiTheme";
import Box from "@mui/material/Box";

export const OtherProjects = () => {
    const { isMobile } = useContext(LayoutContext);
    const { theme } = useContext(ThemeContext);

    return (
            <Box sx={{ paddingTop: isMobile ? 5 : 10, paddingBottom: isMobile ? 5 : 10}}>
                <Typography textAlign="center" variant={ isMobile ? "h5" : "h3"} fontWeight="bold" sx={{ paddingBottom: isMobile ? 3 : 0}}>Matt Selvan's musical projects</Typography>
                {
                    isMobile ? (
                        <Carousel enableSwipe={true} navButtonsAlwaysVisible={true} indicators={false} autoPlay={false} style={{width: '100vw', height: '100vw'}}>
                            {allMattSelvansMusicalProjects.map((project, i) => (
                                <div
                                    style={{
                                        backgroundColor: theme.palette.background.default,
                                        width: '100vw',
                                        height: '100vw',
                                        cursor: project.link? 'pointer' : 'inherit',
                                        backgroundImage: `url(${project.image})`,
                                        backgroundRepeat: 'no-repeat',
                                        backgroundSize: 'cover',
                                        backgroundPosition: 'center',
                                    }}
                                    key={i}
                                    onClick={() => {
                                        const win = project.link && window.open(project.link, '_blank');
                                        win.focus();
                                    }}
                                >
                                    { project.link && (
                                        <OpenInNewIcon
                                            sx={{
                                                fontSize: 60,
                                                color:'#fff',
                                                position: 'absolute',
                                                top: '50%',
                                                left: '50%',
                                                transform: 'translate(-50%, -50%)',
                                                backgroundColor: 'rgba(0,0,0,0.5)',
                                                borderRadius: 2
                                            }}
                                        />
                                    )}
                                </div>
                            ))}
                        </Carousel>
                    ) : (
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            sx={{
                                width: '100vw',
                                gap: 3,
                                paddingTop: 2,
                            }}
                        >
                            {allMattSelvansMusicalProjects.map((project, i) => (
                                    <Box
                                        item
                                        key={i}
                                        sx={{
                                            padding: 0,
                                            margin: 0,
                                            width: 400,
                                            height: 400,
                                            position: 'relative',
                                            overflow: 'hidden',
                                            cursor: project.link? 'pointer' : 'inherit',
                                            backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${project.image})`,
                                            backgroundRepeat: 'no-repeat',
                                            backgroundSize: 'cover',
                                            backgroundPosition: 'center',
                                            ':hover': {
                                                backgroundImage: project.link ?
                                                    `linear-gradient(rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.0)), url(${project.image})`
                                                    :
                                                    `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${project.image})`,
                                            },
                                        }}
                                        onClick={() => {
                                            const win = project.link && window.open(project.link, '_blank');
                                            win.focus();
                                        }}
                                    >
                                        { project.link && (
                                            <OpenInNewIcon
                                                sx={{
                                                    fontSize: 60,
                                                    color:'#fff',
                                                    position: 'absolute',
                                                    top: '50%',
                                                    left: '50%',
                                                    transform: 'translate(-50%, -50%)',
                                                    backgroundColor: 'rgba(0,0,0,0.5)',
                                                    borderRadius: 2
                                                }}
                                            />
                                        )}
                                    </Box>
                            ))}
                        </Grid>
                    )
                }
            </Box>
    );
};
