import React, {useContext, useEffect, useRef} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {MainVideoPlayer} from './MainVideoPlayer';
import {ThemeContext} from '../contexts/ThemeContext';
import {LanguageContext} from "../contexts/LocalizationContext/languages/LanguageContext";
import {Typography} from "@mui/material";
import {LayoutContext} from "../contexts/LayoutContext/LayoutContext";
import {BackgroundVideo} from "./BackgroundVideo";
import {PhotosHomepage} from "./PhotosHomepage";
import {OtherMusicalProjectsBanner, SupportIndependentMusicBanner} from "./SupportIndependentMusicBanner";
import {VerticalGradientLines} from "./ReusableComponents/VerticalGradientLines";
import Box from "@mui/material/Box";
import {globalLogoColorLetterS} from "../styles/muiTheme";
import {Helmet} from "react-helmet";
import {SpotifyIcon} from "../media/platform-icons/music-platforms/SpotifyIcon";
import {socialMediaIcons} from "../displayConfig";
import {
    followOnFacebookSelvans,
    followOnInstagramSelvans,
    followOnYouTubeSelvans,
    shareTwitterSelvans,
    spotifySelvans
} from "../config";
import {OtherProjects} from "./OtherProjects";

export const Homepage = () => {
    const { theme } = useContext(ThemeContext);
    const { isMobile } = useContext(LayoutContext)
    const { dictionary } = useContext(LanguageContext);
    const location = useLocation();
    const navigate = useNavigate();
    const observerRef = useRef();

    useEffect(() => {
        const elementId = location.hash.substring(1); // Remove the leading '#' from the hash
        const element = document.getElementById(elementId);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }, [location]);

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.5,
        };

        const callback = (entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    navigate(`#${entry.target.id}`, { replace: true });
                }
            });
        };

        observerRef.current = new IntersectionObserver(callback, options);

        const targets = document.querySelectorAll(
            '#album, #stories, #bandcamp-album-everything-starts, #watch-and-feel',
        );
        targets.forEach((target) => observerRef.current.observe(target));

        return () => {
            observerRef.current.disconnect();
        };
    }, [navigate]);

    return (
        <>
            <Helmet>
                <title>Home Matt Selvans</title>
                <meta
                    name='description'
                    content="Step into the vibrant musical world of Matt Selvans, a singer-songwriter whose diverse international influences shape his unique sound. Explore the rich tapestry of musical inspirations, from the raw energy of System of a Down to the soulful melodies of Alicia Keys. Dive into the behind-the-scenes glimpses of Matt's life as a musician, from intense studio sessions to soul-stirring rehearsals. Celebrate his musical milestones and discover his passion for percussion and vocals. Join Matt on a heartfelt journey through the art of songwriting, where every lyric is a reflection of life's profound experiences."
                />
                <meta name='keywords' content='Matt Selvans Music, Singer-Songwriter, International Influences, Musical Journey, Behind-the-Scenes, Percussion and Vocals, Songwriting Process, Musical Milestones, Artistic Expression, Passion for Music' />
                <meta property="og:image" content={'https://i.ytimg.com/vi/IOwq0nvU6Uc/maxresdefault.jpg'} />
            </Helmet>
            <div>
            {/*<div id="album">*/}
                <BackgroundVideo />
                {/*<PlyrVideoPlayer/>*/}
            </div>
            <Box bgcolor={globalLogoColorLetterS} width="100%" display="flex" alignItems="center">
            {/*<div id="watch-and-feel">*/}
                    {/*<Typography textAlign="center" variant={ isMobile ? 'h4' : 'h2'} fontWeight="bold">{dictionary.watchAndFeel}</Typography>*/}
                    <Box width="100%">
                        <MainVideoPlayer/>
                    </Box>
            </Box>
            <VerticalGradientLines height={ isMobile ? 100 : 200} mainColor={theme.palette.background.default} secondaryColor={theme.palette.primary.main} gradientLength="110%" >
                <SupportIndependentMusicBanner/>
            </VerticalGradientLines>
            <PhotosHomepage/>
            <OtherProjects />
        </>
    );
};