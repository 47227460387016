
export const MattSelvansTextLogo = ({height}) => {

    const defaultColor = '#101110'

    return(
    <svg height={height} id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 69.3">
        <style>
            {`
    .Drop_x0020_Shadow{fill:none;}
    .Round_x0020_Corners_x0020_2_x0020_pt{fill:${'#FFFFFF'};stroke:#000000;stroke-miterlimit:10;}
    .Live_x0020_Reflect_x0020_X{fill:none;}
    .Bevel_x0020_Soft{fill:url(${'#SVGID_1_'})}
    .Dusk{fill:${'#FFFFFF'};}
    .Foliage_GS{fill:${'#FFDD00'};}
    .Pompadour_GS{fill:rule:evenodd;clip-rule:evenodd;fill:${'#51AEE2'};}
    .letterM{fill:${defaultColor};}
    .firstLetterA{fill:${defaultColor};}
    .firstLetterT{fill:${defaultColor};}
    .secondLetterT{fill:${defaultColor};}
    .firstLetterS{fill:${defaultColor};}
    .firstLetterE{fill:${defaultColor};}
    .firstLetterL{fill:${defaultColor};}
    .firstLetterV{fill:${defaultColor};}
    .secondLetterA{fill:${defaultColor};}
    .firstLetterN{fill:${defaultColor};}
    .secondLetterS{fill:${defaultColor};}
    .st0{fill:none;}
    `}
        </style>

    <path className="secondLetterS" d="M500,50.6v4.7v1.1c0,7.2-5.8,12.9-12.8,12.9h-19.3c-5,0-9.4-2.9-11.5-7.1c-0.3-0.5-0.4-0.9-0.6-1.4
	l16.3-7.3l-1.7,1.3c-3.1,2.4-3.8,7-1.4,10.1c1.2,1.7,3.3,2.8,6.7,2.8h3.7c4.8,0,8.6-3.8,8.6-8.5v-4.4c0-2-1.3-3.8-3.3-4.3l-17.5-5.5
	l-4.5-1.4C458.1,42,455,37.8,455,33v-1.4c0-1.1,0.2-2.2,0.4-3.2c1.4-5.5,6.4-9.7,12.4-9.7h19.3c5,0,9.3,2.9,11.4,7.1
	c0.2,0.5,0.5,0.9,0.7,1.4L483,34.6l1.6-1.3c3.2-2.4,3.9-7,1.5-10.1c-1.3-1.7-3.3-2.9-6.8-2.9h-3.6c-4.7,0-8.5,3.8-8.5,8.6
	c0,2,1.3,3.7,3.2,4.3l17.6,5.6l4.4,1.3C496.9,41.6,500,45.9,500,50.6"/>
    <path className="firstLetterN" d="M450.4,62.7v-31c0-7.1-5.8-12.9-12.9-12.9h-5.1c-1.9,0-3.9,0.4-5.7,1.3l-9,4.1v-0.2
	c0,0-0.1,0.1-0.3,0.2v-5.3h-17c1.4,0.5,2.7,1.3,3.5,2.5c0.8,1.2,1.3,2.6,1.3,4.2V29c-1.8,0.7-3.6,1.5-5.5,2.2v1.6
	c1.9-0.8,3.7-1.5,5.5-2.2v32c0,3-2,5.7-4.8,6.6h4.8h12.2h4.8c-2.9-0.9-4.8-3.6-4.8-6.6V25.8l10.9-4.9c0.9-0.4,1.8-0.6,2.8-0.6
	c3.8,0,7,3.1,7,6.9v35.4c0,3-2,5.7-4.8,6.6h4.8h12.2h4.8C452.3,68.4,450.4,65.7,450.4,62.7z"/>
    <path className="st0" d="M371.6,37.9c-0.2-0.1-0.6-0.2-0.9-0.2l-2.5,1.2c-3.8,1.8-6.3,5.6-6.3,9.8v12.7c0,3.5,2.8,6.4,6.4,6.4h14.3V39
	c-0.8,0.1-1.7,0.1-2.5,0.1C377.5,39.1,374.6,38.8,371.6,37.9z"/>
    <path className="st0" d="M382.6,30.7c0-2.8-1.2-5.4-3.1-7.3c-1.8-1.9-4.4-3-7.3-3c-3.8,0-6.3,1.3-7.8,3.2c-2.2,2.5-2.4,6-1.2,8.9
	c0.4,0.8,1.2,1.6,2.1,1.9l0.5,0.2c1.3,0.5,2.7,1,4,1.3c0.7,0.3,1.5,0.4,2.2,0.7c2.9,0.8,5.7,1.1,8.1,1.1c0.8,0,1.7,0,2.5-0.2
	c0,0,2.8-0.1,7.1-1.8c0,0-4.1,0.6-7.1,0.7V30.7z"/>
    <path className="secondLetterA" d="M394.1,33.4c-1.9-8.4-9.4-14.7-18.4-14.7h-7.2c-7,0-13.1,3.8-16.4,9.5c-0.2,0.4-0.4,0.9-0.7,1.3
	c0,0,7.4,3.9,12.7,6l0.7,0.3c1.2,0.4,2.4,0.8,3.7,1.3l-13.3,6c-3.3,1.5-5.5,4.8-5.5,8.4V60c0,3.3,1.7,6.1,4.2,7.8
	c1.5,0.9,3.2,1.5,5.1,1.5h40.6c-1.4-0.4-2.6-1.3-3.5-2.5c-0.8-1.2-1.3-2.6-1.3-4.1v-25c0-0.9-0.1-1.8-0.2-2.7h0.2
	c1.7-0.7,3.5-1.4,5.2-2.1v-1.6C397.9,32,396,32.7,394.1,33.4z M382.6,67.8h-14.3c-3.6,0-6.4-2.9-6.4-6.4V48.7c0-4.3,2.5-8.1,6.3-9.8
	l2.5-1.2c0.3,0.1,0.7,0.2,0.9,0.2c3.1,0.8,5.9,1.2,8.5,1.2c0.8,0,1.7,0,2.5-0.1V67.8z M382.6,37.4c-0.8,0.2-1.7,0.2-2.5,0.2
	c-2.4,0-5.2-0.3-8.1-1.1c-0.8-0.2-1.6-0.4-2.2-0.7c-1.3-0.3-2.7-0.8-4-1.3l-0.5-0.2c-0.9-0.3-1.7-1.1-2.1-1.9
	c-1.3-2.9-1-6.4,1.2-8.9c1.5-1.8,4-3.2,7.8-3.2c2.9,0,5.5,1.1,7.3,3c1.9,1.9,3.1,4.5,3.1,7.3v5.6c3-0.1,7.1-0.7,7.1-0.7
	C385.4,37.3,382.6,37.4,382.6,37.4z"/>
    <path className="firstLetterV" d="M336,18.8h10.9c-3,0.9-5.4,3.2-6.4,6.3l-14.8,43.1l-0.4,1.2h-1.6h-10.4l-13.7-44.2
	c-0.9-3-3.3-5.4-6.3-6.3h21.8c-2.4,0.8-3.7,3.4-2.9,5.9l12.7,40.9l14-40.8C339.8,22.3,338.5,19.6,336,18.8"/>
    <path className="firstLetterL" d="M294.7,69.4h-21.8c2.9-0.9,4.8-3.6,4.8-6.7v-56c0-1.6-0.5-3-1.3-4.2c-0.8-1.1-2.1-2-3.5-2.5h17v62.7
	c0,1.5,0.5,3,1.3,4.1C292,67.9,293.3,68.9,294.7,69.4"/>
    <path className="firstLetterE" d="M242.6,42v17.3c0,4.7,3.8,8.5,8.6,8.5h7c6.4,0,12.2-4.1,14.3-10.2l-2,10.2l-0.2,1.5h-26.8
	c-1.7,0-3.3-0.3-4.8-0.8c-4.8-1.9-8.1-6.5-8.1-12V31.7c0-7.1,5.8-12.8,12.8-12.8h19.3c5,0,9.3,2.8,11.4,7c0.3,0.5,0.5,1,0.7,1.5
	l-32.2,14.4V42z M242.6,40.1l20.8-9.3v-1.8c0-4.8-3.8-8.6-8.5-8.6h-3.7c-4.8,0-8.6,3.8-8.6,8.6V40.1z"/>
    <path className="firstLetterS" d="M225.2,56.4c-4.3,8.3-14.3,12.8-23.1,12.8h-20.3l-0.3-1.5l-1.9-10.2c2.1,6.1,7.8,10.2,14.3,10.2
	c15.8,0,19-9.9,19.9-12.1c2.1-5.3-0.4-11.3-5.8-14.2l-20.8-10.8c-7.4-4.4-8.1-10.5-7-15.3c1.4-6.3,6.1-11.3,12.1-13.7
	c2.8-1.1,5.7-1.7,8.7-1.8h0.5h1.1h18.6l0.3,1.5l1.9,10.2c-2.1-6.1-7.8-10.2-14.3-10.2h-0.4h-2.6c-15.5,0-18.5,14.6-8.1,21l19.3,10
	l1.2,0.6C225.2,36.7,230.8,45.9,225.2,56.4"/>
    <path className="secondLetterT" d="M142.2,57.9V20.3h9v-1.6h-9V5h-1.4c0,0-2.8,8.5-10.7,12.2c-2,0.9-4.2,1.5-6.9,1.6h-0.9v1.6h0.9h6.9
	v30.5c0,10.2,8.3,18.4,18.4,18.4h3.4C146.4,68.4,142.2,63.6,142.2,57.9z"/>
    <path className="firstLetterT" d="M122.3,5h-1.4c0,0-2.7,8.5-10.7,12.2c-2,0.9-4.2,1.5-6.9,1.6v1.6h6.9v30.5c0,10.2,8.3,18.4,18.4,18.4
	h3.4c-5.6-0.8-9.8-5.7-9.8-11.3V21.3v-0.9v-1.6V5z"/>
    <path className="firstLetterA" d="M108.1,69.3h-6.6H91.1H77.3h-9.8c-1.8,0-3.6-0.5-5-1.5c-2.6-1.6-4.2-4.5-4.2-7.7v-8.6
	c0-3.6,2.1-6.9,5.4-8.4l27.4-12.3v-0.1c0-2.8-1.2-5.4-3-7.3c-1.9-1.8-4.5-3-7.3-3c-3.9,0-6.3,1.3-7.9,3.2c-3.1,3.7-2.3,9.3,1.4,12.3
	l0.7,0.7l-4.7-2.2l-10.3-4.6c0.2-0.5,0.4-0.9,0.7-1.3c3.3-5.8,9.3-9.5,16.4-9.5h7.2c10.4,0,18.9,8.4,18.9,18.9v25
	c0,1.5,0.5,2.9,1.3,4.1C105.4,67.9,106.7,68.9,108.1,69.3 M91.1,32.4l-14.3,6.5c-3.9,1.7-6.4,5.5-6.4,9.8v12.7
	c0,3.6,2.9,6.4,6.4,6.4h8h6.3V32.4z"/>
    <path className="lastletterS" d="M58,69.3H47.1c2.7-0.9,4.7-3.6,4.7-6.5V8.3l-16.5,36L34.5,46v0.4l-0.1-0.3l-6.3-15.3L17,3.9v58.7
	c0,1.6,0.5,3,1.3,4.2c0.8,1.1,2.1,2,3.5,2.5H0c2.9-0.9,4.8-3.6,4.8-6.7v-56c0-1.5-0.5-2.9-1.3-4.1C2.7,1.3,1.4,0.4,0,0h17l11.9,28.9
	L42.2,0H58c-2.8,0.8-4.8,3.5-4.8,6.4v56.4c0,1.5,0.5,2.8,1.3,4C55.4,67.9,56.6,68.8,58,69.3"/>
</svg>

)
}