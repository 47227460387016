import { LanguageProvider } from "./contexts/LocalizationContext/languages/LanguageContext";
import { BrowserRouter } from "react-router-dom";
import {Root} from "./views/Root";
import {CssBaseline, ThemeProvider} from "@mui/material";
import {useContext} from "react";
import {ThemeContext} from "./contexts/ThemeContext";
import {LayoutContextProvider} from "./contexts/LayoutContext/LayoutContext";
import {ThemeContextProvider} from "./contexts/ThemeContext";

export const AppWithTheme = () => {

    const { theme } = useContext(ThemeContext);

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <Root/>
        </ThemeProvider>
    )
}

function App() {

    return (
        <BrowserRouter>
            <ThemeContextProvider>
                <LanguageProvider>
                    <LayoutContextProvider>
                        <AppWithTheme/>
                    </LayoutContextProvider>
                </LanguageProvider>
            </ThemeContextProvider>
        </BrowserRouter>
    );
}

export default App;
