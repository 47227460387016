import {Fragment, useContext, useEffect} from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import {routes} from "../config";
import {Homepage} from "../components/Homepage";
import Footer from "../components/Footer";
import {Discography} from "../components/discography/Discography";
import {Stories} from "../components/Stories";
import PartnersCarousel from "../components/PartnersCarousel/PartnersCarousel";
import {LayoutContext} from "../contexts/LayoutContext/LayoutContext";
import { NavBar } from '../components/navbar/NavBar';
import {AboutMattSelvans} from "../components/AboutMattSelvans";

export const Root = () => {
    const navigate = useNavigate();
    const { isMobile } = useContext(LayoutContext);

    useEffect(() => {
        if (window.location.pathname === "/tbc") {
            navigate(routes.homepage, { replace: true });
            window.location.reload();
        }
    }, [navigate]);

    return(
        <Fragment>
            <NavBar />
            <div style={{marginTop: isMobile? 57 : 60}}/>
            <Routes>
                <Route path={routes.homepage} element={<Homepage/>} />
                <Route path={routes.discography} element={<Discography />} />
                {/*<Route path={routes.stories} element={<Stories />} />*/}
                {/*<Route path={routes.partners} element={<PartnersCarousel />} />*/}
                <Route path={routes.aboutMatt} element={<AboutMattSelvans />} />
            </Routes>
            <Footer/>
        </Fragment>
    )
};
