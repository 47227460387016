import React, {useContext, useState} from 'react';
import {Box, Grid, Link, Modal, Typography} from '@mui/material';
import {ThemeContext} from "../contexts/ThemeContext";
import LocalPostOfficeIcon from '@mui/icons-material/LocalPostOffice';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import {selvansEmailAddress, selvansPhoneNumber} from "../config";
import {LanguageContext} from "../contexts/LocalizationContext/languages/LanguageContext";
import {SelvansLogo} from "../media/logo/SelvansLogo";
import {globalLogoColorLetterM} from "../styles/muiTheme";
import {LayoutContext} from "../contexts/LayoutContext/LayoutContext";
import {MainMenuOptions} from "./navbar/NavBar";

const Footer = () => {
    const { dictionary } = useContext(LanguageContext);
    const { theme } = useContext(ThemeContext)

    const [open, setOpen] = useState(false);

    const handleEmailClick = () => {
        window.location.href = `mailto:${selvansEmailAddress}?subject=Performance Inquiry&body=Hello Matt, I'm interested in hiring you for a performance`;
    };

    const handlePhoneClick = async () => {
        try {
            await navigator.clipboard.writeText(`${selvansPhoneNumber}`);
            setOpen(true);
            setTimeout(() => {
                setOpen(false);
                window.location.href = 'tel:+44-7763-348700';
            }, 600);
        } catch (error) {
            console.error('Failed to copy phone number to clipboard:', error);
        }
    };


    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Box
            component="footer"
            sx={{
                height: '45vh',
                width: '100%',
                overflow: 'hidden',
                backgroundColor: globalLogoColorLetterM,
                opacity: 1,
                p: 2,
                paddingBottom: 4,
                borderTop: '1px solid black',
                boxShadow: theme.shadows[20],
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                bottom: 0,
                fontFamily: 'Source Sans Pro, sans-serif',
                textTransform: 'uppercase',
                fontSize: 15,
                color: '#fff',
                zIndex: 0
            }}
        >
            <Grid container direction="row" justifyContent="center" alignItems="center" style={{ width: '100%', height: '100%', position: 'relative' }}>
                <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: 1 }}>
                    <SelvansLogo uniqueId="footerDark" size={'40vh'} oneColor={'#656565FF'} pulseAnimation={true} />
                </div>

                <Grid
                    item
                    container
                    direction="column"
                    justifyContent={'space-evenly'}
                    alignItems={'flex-start'}
                    sx={{ height: '100%', zIndex: 2 }}
                    md={6} sm={6} xs={6}
                >
                    <p>Contacts</p>
                    <Link
                        onClick={handlePhoneClick}
                        underline="none"
                        style={{color: theme.palette.text.secondary, display: 'flex', flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'center'}}
                    >
                        <PhoneAndroidIcon sx={{color: theme.palette.text.secondary, marginLeft: -0.5}}/>
                        Tel
                    </Link>
                    <Link
                        onClick={handleEmailClick}
                        underline="none"
                        style={{color: theme.palette.text.secondary, display: 'flex', flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'center'}}
                    >
                        <LocalPostOfficeIcon sx={{color: theme.palette.text.secondary}}/>
                        Mail
                    </Link>
                    <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-title"
                        aria-describedby="modal-description"
                    >
                        <Box
                            sx={{
                                position: 'fixed',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                bgcolor: 'background.paper',
                                border: '2px solid white',
                                boxShadow: 24,
                                p: 2,
                            }}
                        >
                            <Typography
                                id="modal-title"
                                variant="h6"
                                component="h2"
                                textAlign="center"
                            >
                                {dictionary.phoneNumberCopied}
                            </Typography>
                        </Box>
                    </Modal>
                </Grid>

                <Grid
                    item
                    container
                    direction="column"
                    justifyContent={'space-evenly'}
                    alignItems={'flex-end'}
                    sx={{ color: '#fff',height: '100%', zIndex: 2 }}
                    md={6} sm={6} xs={6}
                >
                    <MainMenuOptions isColumnMenu={false} fontColor={'#fff'} typographyVariant="body1" cFontWeight="normal" />
                </Grid>

                <Grid item lg={12} sm={12} xs={12}>
                    <Typography textAlign="center" sx={{ marginTop: 1}}>&copy; 2023 Matt Selvans. All rights reserved.</Typography>
                </Grid>
            </Grid>

        </Box>
    );
};

export default Footer;
