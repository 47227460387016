import React, {useContext} from 'react';
import { styled, alpha } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import EditIcon from '@mui/icons-material/Edit';
import ArchiveIcon from '@mui/icons-material/Archive';
import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import {ThemeContext} from "../../contexts/ThemeContext";
import { Link as RouterLink } from "react-router-dom";
import {donatePaypalMattSelvans, selvansDomain, routes} from "../../config";
import {PaypalDonateButton} from "../../media/PaypalDonateButton";
import {SelectLanguageDropDown} from "../SelectLanguageDropDown";
import AlbumIcon from '@mui/icons-material/Album';
import HomeIcon from '@mui/icons-material/Home';
import {MainMenuOptions} from "./NavBar";

const Divider = () => {

    const { theme } = useContext(ThemeContext);

    const dividerStyle = {
        width: '80%',
        height: '1px',
        backgroundColor: theme.palette.secondary.main,
        margin: '0 auto', // Center the divider
    };

    return <div style={dividerStyle}/>;
};

const Link = styled(RouterLink)(({ theme, menuOptionsFontColor }) => ({
    textDecoration: "none",
    color: theme.palette.text.primary,
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
}));

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme, menuOptionsFontColor }) => ({
    '& .MuiPaper-root': {
        position: 'absolute',
        top: 67,
        right: 0,
        zIndex: 10000,
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        overflow: 'hidden',
        color: menuOptionsFontColor,
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.primary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    '#fff',
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));

export default function MenuNavBar() {

    const [anchorEl, setAnchorEl] = React.useState(null);
    const { theme, toggleTheme } = useContext(ThemeContext);
    const menuOptionsFontColor = theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300]

    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(open ? null : event.currentTarget); // Toggle the anchorEl
    };

    const handleClose = () => {
        setAnchorEl(null);
    };


    return (
        <div>
            <IconButton
                id="demo-customized-button"
                aria-controls={open ? 'demo-customized-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                sx={{
                    color: 'inherit',
                    padding: 0,
                    margin: 0,
                    '&:hover': {
                        backgroundColor: 'transparent',
                    },
                }}
            >
                <MenuIcon sx={{ width: 40, height: 40, [theme.breakpoints.down("sm")]: {marginRight: -1}, color: theme.palette.secondary.main}} />
            </IconButton>
            <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                    'aria-labelledby': 'demo-customized-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <MainMenuOptions isColumnMenu={true} handleClose={handleClose} />
                {/*<MenuItem onClick={handleClose} disableRipple>*/}
                {/*    <Link to={routes.homepage} menuOptionsFontColor={menuOptionsFontColor}>*/}
                {/*        <HomeIcon style={{fontSize: 30}}/>*/}
                {/*        Home*/}
                {/*    </Link>*/}
                {/*</MenuItem>*/}
                {/*<Divider/>*/}
                {/*<MenuItem onClick={handleClose} disableRipple>*/}
                {/*    <Link to={routes.aboutMatt} menuOptionsFontColor={menuOptionsFontColor}>*/}
                {/*        <ArchiveIconstylex={fontSize: 30}}/>*/}
                {/*        About Matt*/}
                {/*    </Link>*/}
                {/*</MenuItem>*/}
                {/*<MenuItem onClick={handleClose} disableRipple>*/}
                {/*    <Link to={routes.stories} menuOptionsFontColor={menuOptionsFontColor}>*/}
                {/*        <ArchiveIconstylex={fontSize: 30}}/>*/}
                {/*        Stories*/}
                {/*    </Link>*/}
                {/*</MenuItem>*/}
                {/*<MenuItem onClick={handleClose} disableRipple>*/}
                {/*    <Link to={routes.partners} menuOptionsFontColor={menuOptionsFontColor}>*/}
                {/*        <ArchiveIconstylex={fontSize: 30}}/>*/}
                {/*        Partners*/}
                {/*    </Link>*/}
                {/*</MenuItem>*/}
                {/*<Divider/>*/}
                {/*<MenuItem onClick={() => {toggleTheme();handleClose();}}>*/}
                {/*    {theme.palette.mode === 'dark' ? <Brightness7Icon style={{fontSize: 30}}/> : <Brightness4Icon style={{fontSize: 30}}/>}*/}
                {/*    {theme.palette.mode === 'dark' ? 'light' : 'dark'}*/}
                {/*</MenuItem>*/}
                {/*<Divider/>*/}
                {/*<MenuItem onClick={handleClose} disableRipple>*/}
                {/*    <SelectLanguageDropDown/>*/}
                {/*</MenuItem>*/}
                {/*<Divider/>*/}
                {/*<MenuItem onClick={handleClose} disableRipple>*/}
                {/*    <a href={donatePaypalMattSelvans} target="_blank"  style={{ paddingRight: 5, display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%'}} rel="noreferrer">*/}
                {/*        <PaypalDonateButton height={35} />*/}
                {/*    </a>*/}
                {/*</MenuItem>*/}
            </StyledMenu>
        </div>
    );
}
