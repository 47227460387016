import {useContext} from "react";
import {LanguageContext} from "../contexts/LocalizationContext/languages/LanguageContext";
import {ThemeContext} from "../contexts/ThemeContext";

export const PaypalDonateButton = ({height}) => {

    const { theme } = useContext(ThemeContext)
    const { language } = useContext(LanguageContext)

    const donateWithSpanishItalian =  (language === 'it' || language === 'es') ? theme.palette.mode === 'dark' ? '#000000' : '#ffffff' : 'none'
    const donateWithEnglish = language === 'en' ? theme.palette.mode === 'dark' ? '#000000' : '#ffffff' : 'none'
    const pay = '#003087'
    const pal = '#0070e0'
    const logoUpperP = '#003087'
    const logoShadowInBetween = '#001c64'
    const logoLowerP = '#0070e0'
    const buttonBackgroundColor = theme.palette.mode === 'dark' ? '#fff' : '#000000'
    const buttonStrokeColor = '#A0A0A0'

    return(
        <svg
            version="1.1"
            id="Paypal-button"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 492.1 178.1"
            style={{ enableBackground: 'new 0 0 650 300' }}
            xmlSpace="preserve"
            height={height}
        >
        <style>
         {`
            .donateWithSpanishItalian{fill:${donateWithSpanishItalian};}
            .donateWithEnglish{fill:${donateWithEnglish};}
            .background{fill:${buttonBackgroundColor};stroke:${buttonStrokeColor};stroke-width:3;stroke-miterlimit:10;}
            .pay{fill:${pay};}
            .pal{fill:${pal};}
            .logoLowerP{fill:${logoLowerP};}
            .logoShadowInBetween{fill:${logoShadowInBetween};}
            .logoUpperP{fill:${logoUpperP};}
            .st0{fill:none;}
            .st1{font-family:'Verdana';}
            .st2{font-size:55px;}
            .st3{font-size:43px;}
         `}
        </style>
            <g>
                <path id="Background_00000054227110360407448230000016757232504658921898_" className="background" d="M467.5,176.6H24.6
                        c-12.8,0-23.1-10.3-23.1-23.1V24.6c0-12.8,10.3-23.1,23.1-23.1h442.9c12.8,0,23.1,10.3,23.1,23.1v128.9
                        C490.6,166.3,480.2,176.6,467.5,176.6z"/>
                <path id="logoLowerP" className="logoLowerP" d="M123.7,51.7c0,1.4-0.2,2.8-0.4,4.2c-2.9,18.8-19.1,32.6-38,32.6H66.4
                        c-1.9,0-3.6,1.4-3.9,3.3l-5.9,37.5l-3.7,23.5c-0.3,1.7,0.9,3.4,2.6,3.6c0.2,0,0.3,0,0.5,0h20.5c1.9,0,3.6-1.4,3.9-3.3l5.4-34.2
                        c0.3-1.9,1.9-3.3,3.9-3.3h12.1c19,0,35.1-13.8,38-32.6C141.8,69.8,135.1,57.7,123.7,51.7L123.7,51.7z"/>
                <path id="logoShadowInBetween" className="logoShadowInBetween" d="M72.8,47.7c-1.9,0-3.6,1.4-3.9,3.3l-6.4,40.8
		                c0.3-1.9,1.9-3.3,3.9-3.3h18.9c19,0,35.1-13.8,38-32.6c0.2-1.4,0.3-2.8,0.4-4.2c-4.8-2.5-10.5-4-16.7-4L72.8,47.7z"/>
                <path id="logoUpperP" className="logoUpperP" d="M49.6,20.5c-1.9,0-3.6,1.4-3.9,3.3L29.6,125.7c-0.3,1.9,1.2,3.7,3.1,3.7h23.8l5.9-37.5
		                L69,51c0.3-1.9,1.9-3.3,3.9-3.3H107c6.2,0,11.9,1.4,16.7,4c0.3-17.1-13.8-31.2-33.2-31.2L49.6,20.5z"/>
                <g id="donateWithSpanishItalian">
                    <rect x="184.2" y="20.5" className="st0" width="262.3" height="48.1"/>
                    <text transform="matrix(1 0 0 1 185.4735 62.3192)" className="donateWithSpanishItalian st1 st2">Dona con</text>
                </g>
                <g id="donateWithEnglish">
                    <rect x="184.2" y="27.6" className="st0" width="262.3" height="48.1"/>
                    <text transform="matrix(1 0 0 1 184.2333 60.3211)" className="donateWithEnglish st1 st3">Donate with</text>
                </g>
                <g id="pal">
                    <g>
                        <path className="pal" d="M337.7,89.1c-0.8,0-1.4,0.6-1.5,1.3l-8.8,56c-0.1,0.8,0.4,1.5,1.2,1.6c0.1,0,0.1,0,0.2,0h10.5
				c0.8,0,1.4-0.6,1.5-1.3l2.6-16.4c0.1-0.8,0.8-1.3,1.5-1.3h9.5c11.1,0,20.6-8.1,22.3-19.1c1.7-11.1-6.9-20.8-19.2-20.8H337.7z
				 M347.9,101.5h7.6c6.3,0,8.3,3.7,7.7,7.8c-0.6,4.1-3.8,7.1-9.9,7.1h-7.7L347.9,101.5z M397.9,105.4c-2.6,0-5.7,0.6-9.1,2
				c-7.8,3.3-11.6,10-13.2,14.9c0,0-5.1,15,6.4,23.2c0,0,10.6,7.9,22.6-0.5l-0.2,1.3c-0.1,0.8,0.4,1.5,1.2,1.6c0.1,0,0.1,0,0.2,0
				h9.9c0.8,0,1.4-0.6,1.5-1.3l6-38.3c0.1-0.8-0.4-1.5-1.2-1.6c-0.1,0-0.1,0-0.2,0h-9.9c-0.8,0-1.4,0.6-1.5,1.3l-0.3,2.1
				C410.2,110.1,405.9,105.3,397.9,105.4L397.9,105.4z M398.3,117.5c1.1,0,2.2,0.2,3.1,0.5c4.3,1.4,6.7,5.5,6,10
				c-0.9,5.5-5.4,9.5-11.2,9.5c-1.1,0-2.2-0.2-3.1-0.5c-4.3-1.4-6.7-5.5-6-10C388,121.5,392.5,117.5,398.3,117.5L398.3,117.5z"/>
                        <path className="pal" d="M434.7,89.1c-0.8,0-1.4,0.6-1.5,1.3l-8.8,56c-0.1,0.8,0.4,1.5,1.2,1.6c0.1,0,0.1,0,0.2,0h10.5
				c0.8,0,1.4-0.6,1.5-1.3l8.8-56c0.1-0.8-0.4-1.5-1.2-1.6c-0.1,0-0.1,0-0.2,0L434.7,89.1z"/>
                    </g>
                    <g>
                        <path className="pal" d="M337.7,89.1c-0.8,0-1.4,0.6-1.5,1.3l-8.8,56c-0.1,0.8,0.4,1.5,1.2,1.6c0.1,0,0.1,0,0.2,0h10.5
				c0.8,0,1.4-0.6,1.5-1.3l2.6-16.4c0.1-0.8,0.8-1.3,1.5-1.3h9.5c11.1,0,20.6-8.1,22.3-19.1c1.7-11.1-6.9-20.8-19.2-20.8H337.7z
				 M347.9,101.5h7.6c6.3,0,8.3,3.7,7.7,7.8c-0.6,4.1-3.8,7.1-9.9,7.1h-7.7L347.9,101.5z M397.9,105.4c-2.6,0-5.7,0.6-9.1,2
				c-7.8,3.3-11.6,10-13.2,14.9c0,0-5.1,15,6.4,23.2c0,0,10.6,7.9,22.6-0.5l-0.2,1.3c-0.1,0.8,0.4,1.5,1.2,1.6c0.1,0,0.1,0,0.2,0
				h9.9c0.8,0,1.4-0.6,1.5-1.3l6-38.3c0.1-0.8-0.4-1.5-1.2-1.6c-0.1,0-0.1,0-0.2,0h-9.9c-0.8,0-1.4,0.6-1.5,1.3l-0.3,2.1
				C410.2,110.1,405.9,105.3,397.9,105.4L397.9,105.4z M398.3,117.5c1.1,0,2.2,0.2,3.1,0.5c4.3,1.4,6.7,5.5,6,10
				c-0.9,5.5-5.4,9.5-11.2,9.5c-1.1,0-2.2-0.2-3.1-0.5c-4.3-1.4-6.7-5.5-6-10C388,121.5,392.5,117.5,398.3,117.5L398.3,117.5z"/>
                        <path className="pal" d="M434.7,89.1c-0.8,0-1.4,0.6-1.5,1.3l-8.8,56c-0.1,0.8,0.4,1.5,1.2,1.6c0.1,0,0.1,0,0.2,0h10.5
				c0.8,0,1.4-0.6,1.5-1.3l8.8-56c0.1-0.8-0.4-1.5-1.2-1.6c-0.1,0-0.1,0-0.2,0L434.7,89.1z"/>
                    </g>
                </g>
                <g id="pay" transform="translate(936.898 -21.779)">
                    <g>
                        <path className="pay" d="M-742.3,110.8c-0.8,0-1.4,0.6-1.5,1.3l-8.8,56c-0.1,0.8,0.4,1.5,1.2,1.6c0.1,0,0.1,0,0.2,0h10.5
				c0.8,0,1.4-0.6,1.5-1.3l2.6-16.4c0.1-0.8,0.8-1.3,1.5-1.3h9.5c11.1,0,20.6-8.1,22.3-19.1c1.7-11.1-6.9-20.8-19.2-20.8H-742.3z
				 M-732.1,123.3h7.6c6.3,0,8.3,3.7,7.7,7.8c-0.6,4.1-3.8,7.1-9.9,7.1h-7.7L-732.1,123.3z M-682,127.1c-2.6,0-5.7,0.6-9.1,2
				c-7.8,3.3-11.6,10-13.2,14.9c0,0-5.1,15,6.4,23.2c0,0,10.7,7.9,22.6-0.5l-0.2,1.3c-0.1,0.8,0.4,1.5,1.2,1.6c0.1,0,0.1,0,0.2,0
				h9.9c0.8,0,1.4-0.6,1.5-1.3l6-38.3c0.1-0.8-0.4-1.5-1.2-1.6c-0.1,0-0.1,0-0.2,0h-9.9c-0.8,0-1.4,0.6-1.5,1.3l-0.3,2.1
				C-669.8,131.8-674.1,127.1-682,127.1L-682,127.1z M-681.7,139.2c1.1,0,2.2,0.2,3.1,0.5c4.3,1.4,6.7,5.5,6,10
				c-0.9,5.5-5.4,9.5-11.2,9.5c-1.1,0-2.2-0.2-3.1-0.5c-4.3-1.4-6.7-5.5-6-10C-692,143.3-687.5,139.2-681.7,139.2L-681.7,139.2z"/>
                        <path className="pay" d="M-651.9,128.5c-0.8,0-1.3,0.8-1.1,1.5l10.9,33.8l-9.8,15.9c-0.5,0.8,0.1,1.8,1,1.8h11.6
				c0.7,0,1.3-0.4,1.7-0.9l30.4-50.3c0.5-0.8-0.1-1.8-1-1.8h-11.6c-0.7,0-1.3,0.4-1.7,1l-12,20.2l-6.1-20.1
				c-0.2-0.7-0.8-1.1-1.5-1.1L-651.9,128.5z"/>
                    </g>
                    <g>
                        <path className="pay" d="M-742.3,110.8c-0.8,0-1.4,0.6-1.5,1.3l-8.8,56c-0.1,0.8,0.4,1.5,1.2,1.6c0.1,0,0.1,0,0.2,0h10.5
				c0.8,0,1.4-0.6,1.5-1.3l2.6-16.4c0.1-0.8,0.8-1.3,1.5-1.3h9.5c11.1,0,20.6-8.1,22.3-19.1c1.7-11.1-6.9-20.8-19.2-20.8H-742.3z
				 M-732.1,123.3h7.6c6.3,0,8.3,3.7,7.7,7.8c-0.6,4.1-3.8,7.1-9.9,7.1h-7.7L-732.1,123.3z M-682,127.1c-2.6,0-5.7,0.6-9.1,2
				c-7.8,3.3-11.6,10-13.2,14.9c0,0-5.1,15,6.4,23.2c0,0,10.7,7.9,22.6-0.5l-0.2,1.3c-0.1,0.8,0.4,1.5,1.2,1.6c0.1,0,0.1,0,0.2,0
				h9.9c0.8,0,1.4-0.6,1.5-1.3l6-38.3c0.1-0.8-0.4-1.5-1.2-1.6c-0.1,0-0.1,0-0.2,0h-9.9c-0.8,0-1.4,0.6-1.5,1.3l-0.3,2.1
				C-669.8,131.8-674.1,127.1-682,127.1L-682,127.1z M-681.7,139.2c1.1,0,2.2,0.2,3.1,0.5c4.3,1.4,6.7,5.5,6,10
				c-0.9,5.5-5.4,9.5-11.2,9.5c-1.1,0-2.2-0.2-3.1-0.5c-4.3-1.4-6.7-5.5-6-10C-692,143.3-687.5,139.2-681.7,139.2L-681.7,139.2z"/>
                        <path className="pay" d="M-651.9,128.5c-0.8,0-1.3,0.8-1.1,1.5l10.9,33.8l-9.8,15.9c-0.5,0.8,0.1,1.8,1,1.8h11.6
				c0.7,0,1.3-0.4,1.7-0.9l30.4-50.3c0.5-0.8-0.1-1.8-1-1.8h-11.6c-0.7,0-1.3,0.4-1.7,1l-12,20.2l-6.1-20.1
				c-0.2-0.7-0.8-1.1-1.5-1.1L-651.9,128.5z"/>
                    </g>
                </g>
            </g>
        </svg>
    )
}