import * as React from "react";
import {useContext, useState, useEffect, useCallback, useRef, useMemo} from "react";
import {Box} from "@mui/system";
import {ThemeContext} from "../../contexts/ThemeContext";
import {LanguageContext} from "../../contexts/LocalizationContext/languages/LanguageContext";
import {LayoutContext} from "../../contexts/LayoutContext/LayoutContext";
import Grid from "@mui/material/Unstable_Grid2";
import {styled, Typography} from "@mui/material";
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {en} from "../../contexts/LocalizationContext/languages/en";
import {everythingStartsOfficialCover} from "../../config";
import {VerticalGradientLines} from "../ReusableComponents/VerticalGradientLines";
import {SupportIndependentMusicBanner} from "../SupportIndependentMusicBanner";
import {globalLogoColorLetterM} from "../../styles/muiTheme";
import {Helmet} from "react-helmet";
import SelvansStudioAtIYD from '../../media/OG-Images/Selvans-Studio-At-IYD.jpg'
import AmnesiaSquare from '../../media/everything-starts-album-artworks/Amnesia-square.png'
import StuckSquare from '../../media/everything-starts-album-artworks/Stuck-square.png'

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    const { theme } = useContext(ThemeContext);

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            style={{backgroundColor: theme.palette.background.default}}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 4 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function songsIforTabProps(index) {
    return {
        id: `full-width-songs-info-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

function lyricsLanguageToViewProps(index) {
    return {
        id: `lyrics-language-to-view-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}


const StyledAppBar = styled(AppBar)(({ theme }) => ({
    '&.MuiAppBar-root': {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.text.secondary,
    },
}));

const StyledTabs = styled(Tabs)(({ theme }) => ({
    '&.MuiTabs-root': {
        backgroundColor: `${globalLogoColorLetterM}9A`
    },
}));

const StyledTab = styled(Tab)(({ theme }) => ({
    color: theme.palette.text.secondary,
    fontSize: 20,
    '&.MuiTab-root': {
    },
}));

const StyledTabPanel = styled(Box)(({ theme }) => ({
    '& .MuiBox-root': {
        padding: '0px 0px 0px 10px',
        width: '100%',
        backgroundColor: theme.palette.background.default,
    },
}));

export const Discography = () => {
    const { isMobile } = useContext(LayoutContext);
    const { isDarkMode } = useContext(ThemeContext);
    const { language, dictionary } = useContext(LanguageContext)
    const { theme } = useContext(ThemeContext);

    const everythingStartsAlbum = {
        albumName: 'Everything Starts',
        albumCover: everythingStartsOfficialCover,
        albumHistory: dictionary.everythingStartsAlbumDescription,
        songs: [
            {songName: 'Kiss me', image: 'https://f4.bcbits.com/img/a0425299653_10.jpg', bandCampId: '986348885', originalLyrics: en.kissMeAlbumEverythingStartsLyrics, translatedLyrics: dictionary.kissMeAlbumEverythingStartsLyrics, songHistory: ''},
            {songName: 'If I Don\'t Know', image: 'https://f4.bcbits.com/img/a0558026544_10.jpg', bandCampId: '858553400', originalLyrics: en.ifIDontKnowAlbumEverythingStartsLyrics, translatedLyrics: dictionary.ifIDontKnowAlbumEverythingStartsLyrics, songHistory: ''},
            {songName: 'Be Free', image: 'https://f4.bcbits.com/img/a0696350780_10.jpg', bandCampId: '238993522', originalLyrics: en.beFreeAlbumEverythingStartsLyrics, translatedLyrics: dictionary.beFreeAlbumEverythingStartsLyrics, songHistory: ''},
            {songName: 'Chocolate Lips', image: 'https://f4.bcbits.com/img/a2738069026_10.jpg', bandCampId: '4219887055', originalLyrics: en.chocolateLipsAlbumEverythingStartsLyrics, translatedLyrics: dictionary.chocolateLipsAlbumEverythingStartsLyrics, songHistory: ''},
            {songName: 'Feel Me Now', image: 'https://f4.bcbits.com/img/a1523700926_10.jpg', bandCampId: '3361686036', originalLyrics: en.feelMeNowAlbumEverythingStartsLyrics, translatedLyrics: dictionary.feelMeNowAlbumEverythingStartsLyrics, songHistory: ''},
            {songName: 'Amnesia', image: 'https://f4.bcbits.com/img/a1708991434_10.jpg', bandCampId: '4052073789', originalLyrics: en.amnesiaAlbumEverythingStartsLyrics, translatedLyrics: dictionary.amnesiaAlbumEverythingStartsLyrics, songHistory: ''},
            {songName: 'Stuck', image: 'https://f4.bcbits.com/img/a3072190721_10.jpg', bandCampId: '3236805805', originalLyrics: en.stuckAlbumEverythingStartsLyrics, translatedLyrics: dictionary.stuckAlbumEverythingStartsLyrics, songHistory: ''},
        ]
    }

    const [ songToPlay, setSongToPlay ] = useState(undefined);
    const [songInfoToView, setSongInfoToView] = React.useState(1);
    const [lyricsLanguageToView, setLyricsLanguageToView] = React.useState(0);
    const [ storyToDisplay, setStoryToDisplay ] = useState(everythingStartsAlbum.albumHistory)
    const [ originalLyricsToDisplay, setOriginalLyricsToDisplay ] = useState('')
    const [ translatedLyricsToDisplay, setTranslatedLyricsToDisplay ] = useState('')
    const [ songTitleToDisplay, setSongTitleToDisplay ] = useState(everythingStartsAlbum.albumName)

    const isAlbumCover = useMemo(() => {
        return songTitleToDisplay === everythingStartsAlbum.albumName;
    }, [songTitleToDisplay, everythingStartsAlbum.albumName]);

    useEffect(() => {
        isAlbumCover ? setSongInfoToView(1) : setSongInfoToView(0)
    }, [isAlbumCover]);

    const handleSongInfoToView = (event, newValue) => {
        setSongInfoToView(newValue);

    };const handleLyricsLanguage = (event, newValue) => {
        setLyricsLanguageToView(newValue);
    };

    const dark = '#333333'
    const light = '#ffffff'
    const link = '#0f91ff'
    const selectedSongHighlightColor = '#ffab00'

    const handleSelectedSong = useCallback(
        (bandCampId,songName, songOriginalLyrics, songTranslatedLyrics) => {
            setSongToPlay(bandCampId);
            setSongTitleToDisplay(songName)
            setOriginalLyricsToDisplay(songOriginalLyrics);
            setTranslatedLyricsToDisplay(songTranslatedLyrics);
        },
        [isAlbumCover, songInfoToView]
    );

    useEffect(() => {
        setStoryToDisplay(everythingStartsAlbum.albumHistory);
    }, [dictionary, everythingStartsAlbum.albumHistory]);

    useEffect(() => {
        if (songToPlay) {
            const selectedSong = everythingStartsAlbum.songs.find((song) => song.bandCampId === songToPlay);
            if (selectedSong) {
                setTranslatedLyricsToDisplay(selectedSong.translatedLyrics);
            }
        }
    }, [songToPlay, handleSelectedSong, dictionary, everythingStartsAlbum.songs]);


//enable horizontal scroll onMouseDown
    const [partnerInitialX, setPartnerInitialX] = useState(null);
    const boxRef = useRef(null);

    useEffect(() => {
        const handleMouseDown = (event) => setPartnerInitialX(event.clientX);
        const handleMouseMove = (event) => {
            if (event.buttons === 1 && partnerInitialX !== null) {
                const deltaX = event.clientX - partnerInitialX;
                const boxElement = boxRef.current;
                if (boxElement) {
                    boxElement.scrollLeft -= deltaX;
                    setPartnerInitialX(event.clientX);
                    event.preventDefault();
                }
            }
        };
        const handleMouseUp = (event) => {
            if (event.button === 0) {
                setPartnerInitialX(null);
            }
        };

        window.addEventListener('mousedown', handleMouseDown);
        window.addEventListener('mousemove', handleMouseMove);
        window.addEventListener('mouseup', handleMouseUp);

        return () => {
            window.removeEventListener('mousedown', handleMouseDown);
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', handleMouseUp);
        };
    }, [partnerInitialX]);

    return(
        <Grid container direction="row" justifyContent="center" alignItems="cetner" minHeight="100vh" paddingTop={0} xs={12} overflow="auto" sx={{overflowX: 'auto'}}>
            <Helmet>
                <title>Discography Matt Selvans</title>
                <meta
                    name='description'
                    content="Dive into the soulful discography of Matt Selvans, where each song and album is a chapter in his musical evolution. Explore a diverse spectrum of genres, from rock to reggae, funk to R&B, as Matt's influences come alive in every note. Delve into the stories behind his favorite songs and the heartwarming fan connections that have shaped his artistic path. Get a sneak peek into his upcoming projects, musical challenges, and the travel experiences that have inspired his music. Discover the profound impact of family and international experiences on Matt's extraordinary musical journey."
                />
                <meta name='keywords' content="Matt Selvans Discography, Musical Evolution, Genre Diversity, Favorite Songs, Fan Connections, Upcoming Projects, Musical Challenges, Travel-Inspired Music, Family Influence, Artistic Growth" />
                <meta property="og:image" content={SelvansStudioAtIYD} />
            </Helmet>
            <VerticalGradientLines height={ isMobile ? 100 : 150} mainColor={theme.palette.background.default} secondaryColor={theme.palette.primary.main} gradientLength="110%" >
                <SupportIndependentMusicBanner/>
            </VerticalGradientLines>
            <Grid display="flex" justifyContent="flex-start" alignItems="flex-start" flexDirection="column" textAlign="center" maxWidth={450} sm={12} lg={4}>
                {songToPlay ? (
                    <iframe
                        title={`${songToPlay}`}
                        style={{ border: 0, width: '100%', maxWidth: 450, height: isMobile ? '100vw' : 450, margin: '0 auto' }}
                        src={`https://bandcamp.com/EmbeddedPlayer/album=275801547/size=large/bgcol=${isDarkMode ? dark.substring(1) : light.substring(1)}/linkcol=${link.substring(1)}/tracklist=false/track=${songToPlay}/transparent=true/`}
                        seamless
                    >
                        <a href="https://mattselvans.bandcamp.com/album/everything-starts">Everything Starts by Matt Selvans</a>
                    </iframe>
                ) : (
                    <Box
                        width={ '100%'}
                        height={ isMobile ? '100vw' : 450}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${everythingStartsAlbum.albumCover})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'contain',
                            backgroundPosition: 'center'
                        }}
                    />

                )}
                <Box ref={boxRef} display="flex" flexDirection="row" width="100%" sx={{overflowX: 'auto', scrollbarWidth: 'none', '&::-webkit-scrollbar': {display: 'none',},}}>
                    {[...[{songName: everythingStartsAlbum.albumName, image: everythingStartsAlbum.albumCover, songHistory: everythingStartsAlbum.albumHistory}], ...everythingStartsAlbum.songs].map((song, si) => {
                        return (
                            <Box
                                key={song.bandCampId}
                                display="flex"
                                flexDirection="row"
                                minWidth={120}
                                height={120}
                                onClick={() => handleSelectedSong(song.bandCampId,song.songName, song.originalLyrics, song.translatedLyrics)}
                                sx={{
                                    border: songToPlay === song.bandCampId ? `4px solid ${selectedSongHighlightColor}` : 'none',
                                    overflow: 'hidden',
                                }}
                            >
                                <img
                                    width="100%"
                                    height="100%"
                                    src={song.image}
                                    alt={song.songName}
                                    draggable="false"
                                />
                            </Box>

                        );
                    })}
                </Box>
            </Grid>
            <Grid item lg={8} sm={12} xs={12} sx={{ height: '100%', overflow: 'auto' }}>
                <Box sx={{ bgcolor: 'background.paper' }}>
                    <StyledAppBar position="sticky">
                        <Tabs
                            value={songInfoToView}
                            onChange={handleSongInfoToView}
                            indicatorColor="secondary"
                            textColor="inherit"
                            variant="fullWidth"
                            aria-label="full width tabs example"
                            TabIndicatorProps={{ style: { backgroundColor: theme.palette.primary.main, height: 5 } }}
                            sx={{backgroundColor: globalLogoColorLetterM}}
                        >
                            <Tab disabled={isAlbumCover} label={ isAlbumCover ? '' : dictionary.lyrics} {...songsIforTabProps(0)} sx={{fontSize: 20, display: isAlbumCover ? 'none' : 'block'}}/>
                            <Tab label={dictionary.story} {...songsIforTabProps(1)} sx={{fontSize: 20, display: isAlbumCover ? 'block' : 'none'}}/>
                            <Tab disabled={isAlbumCover} label={ isAlbumCover ? '' : dictionary.credits} {...songsIforTabProps(2)} sx={{fontSize: 20, display: 'none'}}/>
                        </Tabs>
                    </StyledAppBar>
                        { songInfoToView === 0 && (
                            <StyledTabPanel value={songInfoToView} index={0} dir={theme.direction}>
                                    <StyledTabs
                                        value={lyricsLanguageToView}
                                        onChange={handleLyricsLanguage}
                                        indicatorColor="secondary"
                                        textColor="inherit"
                                        aria-label="tabs example"
                                        sx={{p: 0, m: 0, color: theme.palette.primary.main, display: 'none'}}//hidden 2nd tab level
                                        TabIndicatorProps={{ style: { backgroundColor: theme.palette.primary.main, height: 5 } }}
                                    >
                                        { language !== 'en' && (
                                            <StyledTab theme={theme} label={dictionary.translatedToLanguageContext} {...lyricsLanguageToViewProps(0)}/>
                                        )}
                                        <StyledTab theme={theme} label={dictionary.originalLyrics} {...lyricsLanguageToViewProps(1)}/>
                                    </StyledTabs>
                                <TabPanel style={{marginLeft: -10, padding: 8}} value={lyricsLanguageToView} index={0} dir={theme.direction} >
                                    {/*<VerticalGradientLines height={1150} gradientLength="120%" justifyContent="flex-start">*/}
                                        <Typography textAlign="center" fontWeight="bold" variant="h4" sx={{ fontSize: 22, marginTop: theme.spacing(3)}}>{songTitleToDisplay}</Typography>
                                        <Typography
                                            textAlign="center"
                                            variant="h6"
                                            sx={{ whiteSpace: 'pre-line', lineHeight: 1.1 , marginTop: theme.spacing(3) }}
                                        >
                                            {translatedLyricsToDisplay}
                                        </Typography>
                                    {/*</VerticalGradientLines>*/}
                                </TabPanel>
                                <TabPanel style={{marginLeft: -10, padding: 8}} value={lyricsLanguageToView} index={1} dir={theme.direction} >
                                    {/*<VerticalGradientLines height={1000} gradientLength="120%" justifyContent="flex-start">*/}
                                        <Typography textAlign="center" fontWeight="bold" variant="h4" sx={{ fontSize: 22, marginTop: theme.spacing(3)}}>{songTitleToDisplay}</Typography>
                                        <Typography
                                            textAlign="center"
                                            variant="h6"
                                            sx={{ whiteSpace: 'pre-line', lineHeight: 1.1 , marginTop: theme.spacing(3) }}
                                        >
                                            {originalLyricsToDisplay}
                                        </Typography>
                                    {/*</VerticalGradientLines>*/}
                                </TabPanel>
                            </StyledTabPanel>
                            )}
                        <TabPanel value={songInfoToView} index={1} dir={theme.direction}>
                            {/*16 points * 1.33 pixels/point ≈ 21.28 pixels*/}
                            <Typography textAlign="center" sx={{ fontSize: 22, whiteSpace: 'pre-line', lineHeight: 1.1 , marginTop: theme.spacing(3) }}>{storyToDisplay}</Typography>
                        </TabPanel>
                        <TabPanel value={songInfoToView} index={2} dir={theme.direction}>
                            Credits
                        </TabPanel>
                </Box>
            </Grid>
            <VerticalGradientLines height={ isMobile ? 100 : 200} mainColor={theme.palette.background.default} secondaryColor={theme.palette.primary.main} gradientLength="110%" >
                <SupportIndependentMusicBanner/>
            </VerticalGradientLines>
        </Grid>

    )
}