import React, {useContext} from "react";
import {ThemeContext} from "../../contexts/ThemeContext";

export const VerticalGradientLines = ({width, height, mainColor, secondaryColor, gradientLength, justifyContent, children, gradientFromTop}) => {

    const { theme } = useContext(ThemeContext)

    const parentStyle = {
        width: width ? width : '100%',
        height: height ? height : '100%',
        position: 'relative',
        overflow: 'hidden',
    };

    const linesStyle = {
        width: '100%',
        height: '100%',
        backgroundImage: `linear-gradient(to right, ${mainColor ? mainColor : '#52ffb7'} 5px, ${secondaryColor ? secondaryColor : theme.palette.background.default} 5px)`,
        backgroundSize: '10px 100%',
    };

    const gradientMaskStyle = {
        position: 'absolute',
        top: '0',
        left: '0',
        width: '100%',
        height: '200%',
        backgroundImage: `linear-gradient(to ${ gradientFromTop === true ? 'top' : 'bottom' }, transparent 0%, ${mainColor ? mainColor : '#52ffb7'} ${gradientLength ? gradientLength : '85%'})`,
        transform: `translateY(${ gradientFromTop === true ? '0%' : '-50%' })`,
    };

    const boxOverlayStyle = {
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 1,
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: justifyContent ? justifyContent : 'center',
        alignItems: 'center',
    };


    return (
        <div style={parentStyle}>
            <div style={linesStyle}/>
            <div style={gradientMaskStyle}/>
            <div style={boxOverlayStyle}>
                {children}
            </div>
        </div>
    );
};