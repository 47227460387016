import React, { useState } from 'react';
import { Grid } from '@mui/material';
import { PhotosHomePage } from '../displayConfig';
import Carousel from 'react-material-ui-carousel';

import { useContext } from 'react';
import { ThemeContext } from '../contexts/ThemeContext';
import { LayoutContext } from '../contexts/LayoutContext/LayoutContext';
import {globalLogoColorLetterS} from "../styles/muiTheme";

export const PhotosHomepage = () => {
    const { isMobile } = useContext(LayoutContext);
    const { theme } = useContext(ThemeContext);

    const [zoomedIndex, setZoomedIndex] = useState(null); // Initialize to null
    const [cursorCoordinates, setCursorCoordinates] = useState({ x: 0, y: 0 });

    const handleImageToggle = (index, event) => {
        if (event.type === 'click') {
            if (zoomedIndex === null) {
                // If not zoomed, set to current index
                setZoomedIndex(index);
            } else if (zoomedIndex === index) {
                // If already zoomed, reset zoom
                setZoomedIndex(null);
            }
        } else if (event.type === 'mouseenter') {
            const { offsetX, offsetY } = event.nativeEvent;
            setCursorCoordinates({ x: offsetX, y: offsetY });
            if (zoomedIndex !== index) {
                setZoomedIndex(index);
            }
        } else if (event.type === 'mouseleave') {
            setZoomedIndex(null);
        }
    };

    const handleMouseMove = (event) => {
        const { offsetX, offsetY } = event.nativeEvent;
        setCursorCoordinates({ x: offsetX, y: offsetY });
    };

    const getImageStyles = (i) => {
        const scale = zoomedIndex === i ? 2.5 : 1;
        const transformOrigin = `${cursorCoordinates.x}px ${cursorCoordinates.y}px`;

        if (zoomedIndex === i) {
            return {
                transform: `scale(${scale})`,
                transformOrigin: transformOrigin,
                transition: 'transform 0.3s ease',
            };
        } else {
            return {
                transform: `scale(${scale})`,
                transformOrigin: 'center',
                transition: 'transform 0.3s ease',
            };
        }
    };

    return (
            <>
                {
                    isMobile ? (
                        <Carousel enableSwipe={true} navButtonsAlwaysVisible={true} indicators={false} autoPlay={false} style={{width: '100vw', height: '100vw'}}>
                            {PhotosHomePage.map((photo, i) => (
                                <div
                                    style={{
                                        backgroundColor: theme.palette.background.default,
                                        width: '100vw',
                                        height: '100vw',
                                    }}
                                     onMouseMove={handleMouseMove}
                                     onMouseEnter={(event) => handleImageToggle(i, event)}
                                     onMouseLeave={(event) => handleImageToggle(i, event)}
                                     onClick={(event) => handleImageToggle(i, event)}
                                >
                                    <div
                                        style={{
                                            width: '100vw',
                                            height: '100vw',
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            ...getImageStyles(i),
                                        }}
                                    >
                                        <img
                                            width="100%"
                                            height="100%"
                                            src={photo.link}
                                            alt=""
                                            style={{
                                                objectFit: 'cover',
                                                objectPosition: isMobile ? photo.objectPositionMobile : photo.objectPosition,
                                                position: 'absolute',
                                                top: 0,
                                                left: 0,
                                            }}
                                        />
                                    </div>
                                </div>
                            ))}
                        </Carousel>
                    ) : (
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            sx={{
                                backgroundColor: globalLogoColorLetterS,
                                width: '100vw'
                            }}
                        >
                            {PhotosHomePage.map((photo, i) => (
                                    <Grid
                                        item
                                        key={i}
                                        style={{
                                            padding: 0,
                                            margin: 0,
                                            width: 400,
                                            height: 400,
                                            position: 'relative',
                                            overflow: 'hidden',
                                        }}
                                        onMouseMove={handleMouseMove}
                                        onMouseEnter={(event) => handleImageToggle(i, event)}
                                        onMouseLeave={(event) => handleImageToggle(i, event)}
                                        onClick={(event) => handleImageToggle(i, event)}
                                    >
                                        <div
                                            style={{
                                                width: '100%',
                                                height: '100%',
                                                position: 'absolute',
                                                top: 0,
                                                left: 0,
                                                ...getImageStyles(i),
                                            }}
                                        >
                                            <img
                                                width="100%"
                                                height="100%"
                                                src={photo.link}
                                                alt=""
                                                style={{
                                                    objectFit: 'cover',
                                                    objectPosition: isMobile ? photo.objectPositionMobile : photo.objectPosition,
                                                    position: 'absolute',
                                                    top: 0,
                                                    left: 0,
                                                }}
                                            />
                                        </div>
                                    </Grid>
                            ))}
                        </Grid>
                    )
                }
            </>
    );
};
