import { createTheme } from '@mui/material';
import '@fontsource/source-sans-pro';

export const globalLogoColorOuterSquare ='#c4da9d'
export const globalLogoColorVerticalPetals ='#717172'
export const globalLogoColorLetterM ='#292b2b'
export const globalLogoColorLetterS ='#80ab3f'
export const darkBlue = '#000000'

export const darkTheme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: '#80ab3f',
        },
        secondary: {
            main: '#717172',
        },
        divider: '#d93a17',
        background: {
            default: '#ffffff',
            paper: '#ffffff',
        },
        text: {
            primary: '#292b2b',
            secondary: '#fff',
        },
    },
    typography: {
        fontFamily: 'Source Sans Pro, sans-serif',
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    // Hide scrollbar for Chrome, Safari and Opera
                    '&::-webkit-scrollbar': {
                        display: 'none',
                    },
                    // Hide scrollbar for IE, Edge and Firefox
                    '-ms-overflow-style': 'none', // IE and Edge
                    scrollbarWidth: 'none', // Firefox
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    fontFamily: 'Source Sans Pro, sans-serif',
                    color: 'white',
                    backgroundColor: darkBlue,
                    '&:hover': {
                        backgroundColor: '#9d8000',
                    },
                    fontWeight: 'bold',
                },
            },
        },
        MuiTypography: {
            styleOverrides: {
                h1: {
                    fontFamily: 'Libre Bodoni, serif',
                    color: '#292b2b',
                },
                h2: {
                    fontFamily: 'Libre Bodoni, serif',
                    color: '#292b2b',
                },
                h3: {
                    fontFamily: 'Libre Bodoni, serif',
                    color: '#292b2b',
                },
                h4: {
                    fontFamily: 'Libre Bodoni, serif',
                    color: '#292b2b',
                },
                h5: {
                    fontFamily: 'Libre Bodoni, serif',
                    color: '#292b2b',
                },
                h6: {
                    fontFamily: 'Libre Bodoni, serif',
                    color: '#292b2b',
                },
            },
        },
    },
});

export const lightTheme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: '#80ab3f',
        },
        secondary: {
            main: '#717172',
        },
        divider: '#d93a17',
        background: {
            default: '#ffffff',
            paper: '#ffffff',
        },
        text: {
            primary: '#292b2b',
            secondary: '#fff',
        },
    },
    typography: {
        fontFamily: 'Source Sans Pro, sans-serif',
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    // Hide scrollbar for Chrome, Safari and Opera
                    '&::-webkit-scrollbar': {
                        display: 'none',
                    },
                    // Hide scrollbar for IE, Edge and Firefox
                    '-ms-overflow-style': 'none', // IE and Edge
                    scrollbarWidth: 'none', // Firefox
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    color: 'white',
                    backgroundColor: darkBlue,
                    '&:hover': {
                        backgroundColor: '#9d8000',
                    },
                },
            },
        },
        MuiTypography: {
            styleOverrides: {
                h1: {
                    color: '#292b2b',
                },
                h2: {
                    color: '#292b2b',
                },
                h3: {
                    color: '#292b2b',
                },
                h4: {
                    color: '#292b2b',
                },
                h5: {
                    color: '#292b2b',
                },
                h6: {
                    color: '#292b2b',
                },
            },
        },
    },
});
