import * as React from 'react';
import { useContext } from "react";
import {Link} from "react-router-dom";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import MenuNavBar from "./MenuNavBar";
import { SelvansLogo } from "../../media/logo/SelvansLogo";
import { ThemeContext } from "../../contexts/ThemeContext";
import { styled } from "@mui/material/styles";
import {donatePaypalMattSelvans, routes} from "../../config";
import { LayoutContext } from "../../contexts/LayoutContext/LayoutContext";
import { PaypalDonateButton } from "../../media/PaypalDonateButton";
import {MattSelvansTextLogo} from "../../media/logo/MattSelvansTextLogo";
import InfoIcon from '@mui/icons-material/Info';
import AlbumIcon from '@mui/icons-material/Album';
import HomeIcon from '@mui/icons-material/Home';
import MenuItem from '@mui/material/MenuItem';
import {Typography} from "@mui/material";
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const StyledAppBar = styled(AppBar)(({ theme }) => ({
    width: '100%',
    zIndex: 10001,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: 0,
    paddingRight: 0,
    marginLeft: 0,
    marginRight: 0,
    backgroundColor: theme.palette.background.default,
    [theme.breakpoints.up("sm")]: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    // Media query for mobile devices
    [theme.breakpoints.down("sm")]: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },
    // Fade-in and fade-out transitions using visibility and opacity
    opacity: 1,
    visibility: 'visible', // Initially set to visible
    transition: "opacity 0.3s, visibility 0.3s",
    '&.hidden': {
        opacity: 0,
        visibility: 'hidden', // Hide the content, but keep the space occupied
        pointerEvents: 'none', // Disable pointer events when hidden
    },
}));


export const MainMenuOptions = ({ isColumnMenu, handleClose, fontColor, typographyVariant, cFontWeight }) => {
    const { theme } = useContext(ThemeContext);
    const location = useLocation();

    //onPageChange scroll all the way up
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [location]);

    const menuOptions = [
        { route: routes.homepage, name: 'Home', icon: <HomeIcon /> },
        { route: routes.discography, name: 'Discography', icon: <AlbumIcon /> },
        { route: routes.aboutMatt, name: 'About', icon: <InfoIcon /> },
        { url: donatePaypalMattSelvans, name: 'Donate', icon: <PaypalDonateButton height={20}/> },
    ];

    const menuItemsColor = fontColor ? fontColor : '#000'

    return (
        <>
            {isColumnMenu === true ? (
                menuOptions.map((menuOption, i) => (
                    <MenuItem onClick={handleClose} disableRipple key={i} style={{ display: 'flex', alignItems: 'center' }}>
                        { menuOption.url ? (
                            <a
                                id={'urlCol'}
                                href={menuOption.url}
                                target="_blank"
                                rel="noreferrer"
                                style={{
                                    textDecoration: 'none',
                                    color: menuItemsColor,
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                {menuOption.icon}
                                {menuOption.name}
                            </a>
                        ) : (
                            <Link
                                id={i}
                                to={menuOption.route}
                                style={{
                                    textDecoration: 'none',
                                    color: menuItemsColor,
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                {menuOption.icon}
                                {menuOption.name}
                            </Link>
                        )}
                    </MenuItem>
                ))
            ) : (
                menuOptions.map((menuOption, i) => (
                    menuOption.url ? (
                        <a id={'url'} href={menuOption.url} target="_blank" rel="noreferrer" style={{textDecoration: 'none', color: menuItemsColor, display: 'flex', alignItems: 'center'}}>
                            <Typography variant={ typographyVariant ? typographyVariant : 'h6'} sx={{fontFamily: 'Source Sans Pro, sans-serif', textTransform: 'uppercase', color: menuItemsColor, fontWeight: cFontWeight ? cFontWeight : 'bold' }}>{menuOption.name}</Typography>
                        </a>
                    ) : (
                        <Link
                            id={i}
                            to={menuOption.route}
                            key={i}
                            style={{
                                textDecoration: 'none',
                                color: menuItemsColor,
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <Typography variant={ typographyVariant ? typographyVariant : 'h6'} sx={{fontFamily: 'Source Sans Pro, sans-serif', textTransform: 'uppercase', color: menuItemsColor, fontWeight: cFontWeight ? cFontWeight : 'bold' }}>{menuOption.name}</Typography>
                        </Link>
                    )
                ))
            )}
        </>
    );
};



export const NavBar = () => {
    const { isNavBarVisible, isMobile, isTablet } = useContext(LayoutContext);
    const { theme } = useContext(ThemeContext);

    return (
        <React.Fragment>
            <StyledAppBar
                component="nav"
                position="fixed"
                className={isNavBarVisible ? '' : 'hidden'}
            >
                <Toolbar sx={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center", }} disableGutters={true}>
                    <Box>
                        <Link to={routes.homepage}>
                            <Box display="flex" flexDirection="row" alignItems="center" gap={1}>
                                <SelvansLogo uniqueId="navbarLogo" size={50} pulseAnimation={true} />
                                <MattSelvansTextLogo height={20} />
                            </Box>
                        </Link>
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center", gap: "30px", [theme.breakpoints.down("sm")]: { gap: "3px" } }}>
                        { ( isMobile || isTablet ) ? <MenuNavBar /> : <MainMenuOptions />}
                    </Box>
                </Toolbar>
            </StyledAppBar>
        </React.Fragment>
    );
}
