import { createUseStyles } from 'react-jss';
import React, { useContext, useEffect, useState, useRef } from 'react';
import { Box, Button, styled, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import {BackgroundPlayerData, MainVideoPlayerVideoData, socialMediaIcons} from '../displayConfig';
import { ThemeContext } from '../contexts/ThemeContext';
import { Link } from 'react-router-dom';
import { LayoutContext } from '../contexts/LayoutContext/LayoutContext';
import {
    buyEverthingStartsBandcamp,
    followOnFacebookSelvans,
    followOnInstagramSelvans,
    followOnYouTubeSelvans
} from '../config';
import { LanguageContext } from '../contexts/LocalizationContext/languages/LanguageContext';
import { SelvansLogo } from '../media/logo/SelvansLogo';

const useStyles = createUseStyles(({isTablet, isMobile}) => ({
    backgroundVideoContainer: {
        display: 'flex',
        width: '100%',
        height: '100%',
        backgroundColor: '#000000',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000,
    },
    disableFrameControlsBackgroundVideoContainer: {
        height: '100%',
        width: '100%',
        top: 0,
        display: 'flex',
        position: 'absolute',
        justifyContent: 'space-between',
        alignItems: 'center',
        left: 0,
        right: 0,
        zIndex: 999,
    },
    disableFrameControlsBackgroundVideo: {
        display: 'flex',
        bottom: 0,
        width: '100%',
        height: '100%',
        cursor: 'pointer',
        justifyContent: 'flex-start',
        zIndex: 999,
    },
}));

const mobilePlayerHeight = '68vw'
const mobileAlbumButtonsHeight = '16vw'
const mobileSocialButtonsHeight = parseFloat(mobilePlayerHeight) - parseFloat(mobileAlbumButtonsHeight) + 'vw';

const ContainerBackgroundVideo = styled('div')(({ isMobile, containerWidth }) => ({
    position: 'relative',
    width: containerWidth,
    height: 0,
    paddingTop: isMobile ? '68vw' : `calc(${containerWidth} * 0.5625)`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#000000',
    '& video': {
        position: 'absolute',
        top: isMobile ? '-10vw' : 0,
        left: 0,
        width: '100%',
        height: '100%',
    },
}));

export const BackgroundVideo = () => {
    const { theme } = useContext(ThemeContext);
    const { isMobile, isIOS, isTablet } = useContext(LayoutContext);
    const { dictionary } = useContext(LanguageContext);

    const socialMedia = [
        // {icon: <SpotifyIcon/>, link: spotifySelvans},
        {icon: socialMediaIcons.facebook, link: followOnFacebookSelvans},
        {icon: socialMediaIcons.instagram, link: followOnInstagramSelvans},
        {icon: socialMediaIcons.youTube, link: followOnYouTubeSelvans},
        // {icon: socialMediaIcons.twitter, link: shareTwitterSelvans},
    ]
    const iconSize = isMobile ? 35 : 50

    const location = useLocation();
    const videoRef = useRef(null);

    const [currentIndexBackgroundVideo, setCurrentIndexBackgroundVideo] = useState(0);

    const videos = BackgroundPlayerData();

    const classes = useStyles();

    const handleNextBackgroundVideo = () => {
        setCurrentIndexBackgroundVideo((prevIndex) => (prevIndex + 1) % videos.length);
    };

    const handleVideoEnded = () => {
        handleNextBackgroundVideo();
        videoRef.current.play();
    };

    useEffect(() => {
        const video = videoRef.current;
        video.addEventListener('ended', handleVideoEnded);

        return () => {
            video.removeEventListener('ended', handleVideoEnded);
        };
    }, []);

    useEffect(() => {
        if (location.hash === '#album') {
            videoRef.current.play();
        }
    }, [location]);

    return (
        <div className={classes.backgroundVideoContainer} style={{ paddingTop: isMobile ? 15 : isTablet ? 4 : 4 }}>
            <ContainerBackgroundVideo containerWidth={'100%'} isMobile={isMobile}>
                <div className={classes.disableFrameControlsBackgroundVideoContainer}>
                    <div className={classes.disableFrameControlsBackgroundVideo} onClick={() => videoRef.current.play()} />
                </div>
                <video
                    ref={videoRef}
                    width="100%"
                    height="100%"
                    autoPlay
                    muted
                    playsInline
                    onEnded={handleVideoEnded}
                >
                    <source src={require('../media/videos/WebBgVideo.mp4')} type="video/mp4" />
                </video>
                <div style={{ position: 'absolute', top: isMobile ? '-1vw' : isTablet ? '5%' : '8vw', zIndex: 1 }}>
                    <SelvansLogo uniqueId="backgroundVideoLogo" size={isMobile || isTablet ? '50vw' : '40vw'} onlyOneColorNoSquare={`${theme.palette.primary.main}9A`} />
                </div>
                <Box
                    sx={{
                        width: '100%',
                        height: isMobile ? mobileAlbumButtonsHeight : 'auto',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        position: 'absolute',
                        bottom: isMobile ? 0 : isTablet ? '17vw' : '20vw',
                        backgroundColor: isMobile ? '#fff' : 'none',
                        gap: isMobile ? '16vw' : isTablet ? '4vw' : 10,
                        zIndex: 1000
                    }}
                >
                    <Button component={Link} to={`/discography`} variant="contained" sx={{ backgroundColor: theme.palette.primary.main }}>
                        <Typography fontWeight="bold" sx={{ fontSize: isMobile ? 13 : 25 }}>
                            {dictionary.exploreAlbum}
                        </Typography>
                    </Button>
                    <Button onClick={() => { window.open(buyEverthingStartsBandcamp, '_blank'); }} variant="contained">
                        <Typography fontWeight="bold" sx={{ fontSize: isMobile ? 13 : 25 }}>
                            {dictionary.purchaseNow}
                        </Typography>
                    </Button>
                </Box>
                <Box
                    sx={{
                        height: isMobile ? mobileSocialButtonsHeight : '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: isMobile? 'flex-start': 'center',
                        position: 'absolute',
                        left: ( isMobile || isTablet ) ? 5 : 80,
                        top: isMobile ? '-2vw' : 0,
                        gap: isMobile? 3 : isTablet ? 6 : 10,
                        zIndex: 9999,
                    }}
                >
                    {socialMedia.map((link, index) => (
                        <a href={link.link} target="_blank" rel="noopener noreferrer" key={index} style={{display: 'flex', alignItems: 'center',}}>
                            {React.cloneElement(link.icon, { size: iconSize })}
                        </a>
                    ))}
                </Box>
            </ContainerBackgroundVideo>
        </div>
    );
};
