import React, {useContext, useMemo} from "react";
import bbqSelvans from './media/PhotosHomepage/01-bbq-selvans.jpg';
import oliviaRandom from './media/PhotosHomepage/02-olivia-and-the-random.jpg';
import otroEdu from './media/PhotosHomepage/03-otro-edu.jpg';
import lalaCatedralKid from './media/PhotosHomepage/04-lala-catedral-kid.jpg';
import otroSoloPerformance from './media/PhotosHomepage/05-otro-solo-performance.jpg';
import lalaSunny from './media/PhotosHomepage/06-lala-sunny.jpg';
import lalaBlackWhiteRedScarf from './media/PhotosHomepage/07-lala-black-white-red-scarf.jpg';
import newcastleRiverSide from './media/PhotosHomepage/08-newcastle-river-side.jpg';
import highlandsStuck from './media/PhotosHomepage/09-highlands-stuck.jpg';
import {
    FacebookTwoColorIcon,
    InstagramTwoColorIcon,
    SoundCloudTwoColorIcon,
    TikTokTwoColorIcon,
    TwitterTwoColorIcon,
    WebsiteTwoColorIcon,
    YouTubeTwoColorIcon
} from "./media/TwoColorSocialMediaIcons";
import {SelvansLogo} from "./media/logo/SelvansLogo";
import {CampingInTheSkiesLogo} from "./media/logo/CampingInTheSkiesLogo";
import {LanguageContext} from "./contexts/LocalizationContext/languages/LanguageContext";
import {
    albumEveythingStartsBandcamp,
    amazonMusicSelvans, animalTendencyFacebook,
    appleMusicSelvans, campingInTheSkiesWeb,
    followOnFacebookSelvans,
    followOnInstagramSelvans,
    followOnWhatsAppSelvans,
    followOnYouTubeSelvans,
    selvansDomain,
    selvansWebsite,
    shareFacebookSelvans,
    shareOnWhatsAppThe,
    shareTwitterSelvans,
    spotifySelvans,
    youTubeMusicSelvans
} from "./config";
import LibraryMusicIcon from "@mui/icons-material/LibraryMusic";
import {BandCampIcon} from "./media/platform-icons/music-platforms/BandCampIcon";
import {YouTubeMusicIcon} from "./media/platform-icons/music-platforms/YouTubeMusicIcon";
import {AmazonMusicIcon} from "./media/platform-icons/music-platforms/AmazonMusicIcon";
import {AppleMusicIcon} from "./media/platform-icons/music-platforms/AppleMusicIcon";
import {SpotifyIcon} from "./media/platform-icons/music-platforms/SpotifyIcon";
import AddReactionIcon from "@mui/icons-material/AddReaction";
import {WhatsAppIcon} from "./media/platform-icons/social-media-platforms/WhatsAppIcon";
import {FacebookIcon} from "./media/platform-icons/social-media-platforms/FacebookIcon";
import {InstagramIcon} from "./media/platform-icons/social-media-platforms/InstagramIcon";
import {YouTubeIcon} from "./media/platform-icons/social-media-platforms/YouTubeIcon";
import ShareIcon from "@mui/icons-material/Share";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {TwitterIcon} from "./media/platform-icons/social-media-platforms/TwitterIcon";
import CampingInTheSkiesKrakatoa from '../src/media/other-projects-photos/CampingInTheSkiesKrakatoa.jpg'
import AnimalTendencyAguiar from '../src/media/other-projects-photos/AnimalTendencyAguiar.jpg'
import MattSelvansCompleteLogoImage from '../src/media/other-projects-photos/MattSelvansCompleteLogoImage.jpg'

export const allMattSelvansMusicalProjects = [
    { name: 'Camping In The Skies', link: campingInTheSkiesWeb, image: CampingInTheSkiesKrakatoa},
    { name: 'Matt Selvans', link: false, image: MattSelvansCompleteLogoImage},
    { name: 'Animal Tendency', link: animalTendencyFacebook, image: AnimalTendencyAguiar},
]

export const PhotosHomePage = [
    {description: 'Before being busker', objectPosition: 'center', objectPositionMobile: 'center', link: bbqSelvans },
    {description: 'Rehearsing with Olivia and the Random', objectPosition: 'center top', objectPositionMobile: 'center', link: oliviaRandom },
    {description: 'Otro with Edu', objectPosition: 'center', objectPositionMobile: 'center', link: otroEdu },
    {description: 'Busking Tenerife with kid', objectPosition: 'center', objectPositionMobile: 'center', link: lalaCatedralKid },
    {description: 'Otro by Ana', objectPosition: 'center', objectPositionMobile: 'center', link: otroSoloPerformance },
    {description: 'Buskin in la Laguna', objectPosition: 'center', objectPositionMobile: 'center', link: lalaSunny },
    {description: 'Grayscale Laguna', objectPosition: 'center top', objectPositionMobile: 'center top', link: lalaBlackWhiteRedScarf },
    {description: 'Newcastle near river', objectPosition: 'center', objectPositionMobile: 'right top', link: newcastleRiverSide },
    {description: 'Snow in Scotland', objectPosition: 'center', objectPositionMobile: 'center', link: highlandsStuck },
]

export const socialMediaIcons = {
    youTube: <YouTubeTwoColorIcon/>,
    facebook: <FacebookTwoColorIcon/>,
    instagram: <InstagramTwoColorIcon/>,
    twitter: <TwitterTwoColorIcon/>,
    tikTok: <TikTokTwoColorIcon/>,
    website: <WebsiteTwoColorIcon/>,
    soundCloud: <SoundCloudTwoColorIcon/>,
}
export const partnersDataArray = [
    {
        imageSrc: "https://campingintheskies.com/wp-content/uploads/2023/04/OmarStandard.jpg",
        name: "Omar",
        job: "Photographer",
        story: "Omar, the son of Cuzco and nephew of Kronk, has been our cherished photographer since April 2019. With his keen eye and artistic vision, he has captured our best moments and ideas, preserving them forever in stunning images and videos. His talent behind the lens has allowed us to relive those memories time and time again.",
        socialLinks: [
            {icon: socialMediaIcons.instagram, link: "https://www.instagram.com/omar_ddok/"},
            {icon: socialMediaIcons.facebook, link: "https://www.facebook.com/odocherty/about"},
        ]
    },
    {
        imageSrc: "https://campingintheskies.com/wp-content/uploads/2023/05/BjStandard1.jpg",
        name: "Bijay Shrestha",
        job: "Bass player",
        story: "Bijay from Kathmandu, Nepal played bass since his early teens. His brother Bikrum played drums. Bijay tattooed and studied Nepali art. He worked as a session musician with various bands: Tamishira, X-Mantra, Ugrakarma, Monkey Temple, Urban Gypsy, Karma Band. Touring India, China, Europe, Bijay ran tattoo studios and a restaurant. Moved to Scotland, tattoos professionally in Edinburgh and plays bass for Camping in the Skies. Bijay's influences: Bikrum Shrestha, Victor Wooten, Richard Bona, Billy Sheehan, Dimebag Darrel, Max Calvalera.",
        socialLinks: [
            {icon: socialMediaIcons.instagram, link: "https://www.instagram.com/bjbassnepal/"},
            {icon: socialMediaIcons.instagram, link: "https://www.instagram.com/tatt_art_nepal/"}
        ]
    },
    {
        imageSrc: "https://campingintheskies.com/wp-content/uploads/2023/05/MonniStandard2.jpg",
        name: "Monni S. Kamenov",
        job: "Drummer",
        story: "Monni is a drummer from Bulgaria, a land of ancient heroes and legends. He met Rodaidh in the misty highlands of Scotland, where they forged a musical bond by sacrificing a guitar and a drum kit to the gods. He dwells in the rehearsing rooms around Edinburgh, always ready for action and adventure.",
        socialLinks: [
            {icon: socialMediaIcons.facebook, link: "https://www.facebook.com/monni.s.kamenov"},
            {icon: socialMediaIcons.instagram, link: "https://www.instagram.com/monni_bg/"},
            {icon: socialMediaIcons.youTube, link: "https://www.youtube.com/channel/UCXZCuwwto3CMRl9uqvGlRPg"},
            {icon: socialMediaIcons.soundCloud, link: "https://soundcloud.com/simeon-kamenov"}
        ]
    },
    {
        imageSrc: "https://campingintheskies.com/wp-content/uploads/2023/05/BeedieStandard2.jpg",
        name: "Liam Beedie",
        job: "Drummer",
        story: "Liam and Rodaidh had a successful band together before, but destiny threw a curveball at them. They decided to overcome the challenge and create Camping In The Skies along with Scott and Matt. Liam is the son of Thor, but he prefers drum sticks to a hammer. Being the son of a god, he has a captivating rhythm on the drums that enchants everyone who listens to him. He is also a devoted father of two, who are inheriting his dark magic.",
        socialLinks: [
            {icon: socialMediaIcons.instagram, link: "https://www.instagram.com/beedieboy7/"},
            {icon: socialMediaIcons.facebook, link: "https://www.facebook.com/beedieboy"}
        ]
    },
    {
        imageSrc: "https://campingintheskies.com/wp-content/uploads/2023/04/StuStandard.jpg",
        name: "Stuart Airzee",
        job: "Bass player",
        story: "Stu hails from the north east of Scotland, land of rugged beauty and wild spirit. He fell in love with the bass at 14 and has played in many bands throughout the years, spreading his groove and passion. He's currently the bass player for The Funky Red Pandas, a band that blends funk, soul, and rock with a touch of Scottish flair. His influences include: RHCP, Jamiroquai, Vulfpeck, Chic and anything funky! \"I've known Rodaidh for a good few, he's a great friend and musician. When he asked me if I wanted to work on some tracks with CITS, I was thrilled and eager to get involved.\"",
        socialLinks: [
            {icon: socialMediaIcons.youTube, link: "https://youtube.com/@stuartairzee4618"},
            {icon: socialMediaIcons.website, link: "https://thefunkyredpandas.band/"}
        ]
    },
    {
        imageSrc: "https://campingintheskies.com/wp-content/uploads/2023/04/Samuel-Bradley-standard.jpg",
        name: "Samuel M Bradley",
        job: "Bass player",
        story: "Samuel Bradley is a bass guitarist and double bassist who can bring any song to life with his versatile and expressive playing. He is available for sessions and remote recording, as well as composing and arranging original music. He also offers friendly bass tuition in Glasgow's Southside, where he shares his passion and expertise with aspiring musicians. Samuel Bradley is not just a bass player, he is a musical storyteller.",
        socialLinks: [
            {icon: socialMediaIcons.facebook, link: "https://www.facebook.com/samuel.bradley.9674"},
            {icon: socialMediaIcons.website, link: "https://samuelmbradley.com/"}
        ]
    },
    {
        imageSrc: "https://campingintheskies.com/wp-content/uploads/2022/09/ScottBLueTrim.png",
        name: "Scott from Scotland",
        job: "Bass player",
        story: "Scott, also known as \"phylum Porifera\" or \"Sponge\" for the barbarians, was a core member of Camping In The Skies. He had a strong symbiosis with Beedie, the son of Thor. Rodaidh and him crafted a beautiful blend of soundwaves that Matt couldn't help but sing along. He now became a Mormon and he's spreading God's word in Siberia. Jokes aside, he suddenly felt a divine inspiration to create songs in a new genre worthy of the gods.",
        socialLinks: []
    },
    {
        imageSrc: "https://campingintheskies.com/wp-content/uploads/2022/09/dOMbLUE.png",
        name: "Dom Owtram",
        job: "Drummer",
        story: "Dom met Rodaidh during a fateful audition for a music project at uni. Although unable to fully commit to the band at the time, their connection was undeniable. Dom has since stepped in as the heartbeat of CITS, live and remotely recording at various points since the group's inception. Drummer and producer for Gloucester Ln. Dom's influences include legendary drummers: Chad Smith(RHCP), Mike Portnoy(DT), Gavin Harrison(Porcupine Tree), Adam Deitch(Lettuce), Ian Paice(Deep Purple), Tre Cool(Green Day) and Travis Barker.",
        socialLinks: [{icon: socialMediaIcons.website, link: "https://linktr.ee/gloucesterln"},]
    },
]

export const MainVideoPlayerVideoData = () => {
    return useMemo(() => (
        [
            {
                name: 'Amnesia Official Music Video',
                videoId: 'IOwq0nvU6Uc',
                start: 220,
                end: 230,
                ytThumbnail: 'https://i.ytimg.com/vi/IOwq0nvU6Uc/maxresdefault.jpg',
                logo: <SelvansLogo />
            },
            {
                name: 'Stuck Official Music Video',
                videoId: '6kA_GjN6cOU',
                start: 17,
                end: 60,
                ytThumbnail: 'https://i.ytimg.com/vi/6kA_GjN6cOU/maxresdefault.jpg',
                logo: <SelvansLogo />
            },
            // {
            //     name: 'Disobey Official Music Video',
            //     videoId: 'https://www.youtube.com/embed/FTlNNAjyDr0',
            //     start: 331,
            //     end: 345,
            //     ytThumbnail: 'https://i.ytimg.com/vi/FTlNNAjyDr0/maxresdefault.jpg',
            //     logo: <CampingInTheSkiesLogo />
            // },
            // {
            //     name: 'Facebook test',
            //     videoId: 'https://www.facebook.com/plugins/video.php?height=309&href=https%3A%2F%2Fwww.facebook.com%2Fcampingintheskies%2Fvideos%2F271981765181884%2F&show_text=false&width=560&t=0',
            //     logo: <CampingInTheSkiesLogo />
            // },
            // {
            //     name: 'Camping In The Skies Official Music Video',
            //     videoId: 'https://www.youtube.com/embed/v5QQHKf1L58',
            //     start: 331,
            //     end: 345,
            //     ytThumbnail: 'https://i.ytimg.com/vi/v5QQHKf1L58/maxresdefault.jpg',
            //     logo: <CampingInTheSkiesLogo />
            // },
            // {
            //     name: 'Moving On Official Music Video',
            //     videoId: 'https://www.youtube.com/embed/-L40ZD2JVxc',
            //     start: 134,
            //     end: 145,
            //     ytThumbnail: 'https://i.ytimg.com/vi_webp/-L40ZD2JVxc/maxresdefault.webp',
            //     logo: <CampingInTheSkiesLogo />
            // },
            // {
            //     name: 'Camping In The Skies Live Acoustic Video',
            //     videoId: 'https://www.youtube.com/embed/BSmIi6AptEw',
            //     start: 283,
            //     end: 315,
            //     ytThumbnail: 'https://i.ytimg.com/vi_webp/BSmIi6AptEw/maxresdefault.webp',
            //     logo: <CampingInTheSkiesLogo />
            // },
        ]
    ), []);
};

export const BackgroundPlayerData = () => {
    return useMemo(() => (
        [
            {
                name: 'Stuck Official Music Video',
                videoId: 'https://www.youtube.com/embed/6kA_GjN6cOU',
                start: 6,
                end: 10,
                // extraClips: [{start: 64, end: 69,}, {start: 85, end: 88,}, {start: 100, end: 104,}]
            },
            {
                name: 'Amnesia Official Music Video',
                videoId: 'https://www.youtube.com/embed/IOwq0nvU6Uc',
                start: 21,
                end: 36,
            },
            {
                name: 'Stuck Official Music Video',
                videoId: 'https://www.youtube.com/embed/6kA_GjN6cOU',
                start: 187,
                end: 196,
            },
            {
                name: 'Amnesia Official Music Video',
                videoId: 'https://www.youtube.com/embed/IOwq0nvU6Uc',
                start: 152,
                end: 168,
            },
            {
                name: 'Stuck Official Music Video',
                videoId: 'https://www.youtube.com/embed/6kA_GjN6cOU',
                start: 17,
                end: 39,
            },
        ]
    ), []);
};
export const aboutUsCarouselImages = [
    {image: 'https://mattselvans.com/wp-content/uploads/2023/05/Map-recording-768x634.jpg', alt: 'Singing home'},
    {image: 'https://mattselvans.com/wp-content/uploads/2023/05/Map-Busking-2022-768x634.jpg', alt: 'Busking map'},
    {
        image: 'https://mattselvans.com/wp-content/uploads/2023/05/BuskingNewCastle-Text-768x634.png',
        alt: 'Busker since 2011'
    },
];
export const ListenFollowSharePlatforms = ({size}) => {
    const {dictionary} = useContext(LanguageContext);

    const shareOnWhatsAppSelvans = `${shareOnWhatsAppThe}Have a listen to this guy %0A${selvansDomain}`

    return useMemo(() => (
        [
            {
                name: dictionary.listen,
                icon: LibraryMusicIcon,
                platforms: [
                    {
                        name: 'Bandcamp',
                        link: albumEveythingStartsBandcamp,
                        icon: <BandCampIcon size={size}/>
                    },
                    {
                        name: 'YouTube Music',
                        link: youTubeMusicSelvans,
                        icon: <YouTubeMusicIcon size={size}/>
                    },
                    {
                        name: 'Amazon Music',
                        link: amazonMusicSelvans,
                        icon: <AmazonMusicIcon size={size}/>
                    },
                    {
                        name: 'Apple Music',
                        link: appleMusicSelvans,
                        icon: <AppleMusicIcon size={size}/>
                    },
                    {
                        name: 'Spotify',
                        link: spotifySelvans,
                        icon: <SpotifyIcon size={size}/>
                    },
                ]
            },
            {
                name: dictionary.follow,
                icon: AddReactionIcon,
                platforms: [
                    {
                        name: 'WhatsApp',
                        link: followOnWhatsAppSelvans,
                        icon: <WhatsAppIcon size={size}/>
                    },
                    {
                        name: 'Facebook',
                        link: followOnFacebookSelvans,
                        icon: <FacebookIcon size={size}/>
                    },
                    {
                        name: 'Instagram',
                        link: followOnInstagramSelvans,
                        icon: <InstagramIcon size={size}/>
                    },
                    {
                        name: 'YouTube',
                        link: followOnYouTubeSelvans,
                        icon: <YouTubeIcon size={size}/>
                    },
                ]
            },
            {
                name: dictionary.share,
                icon: ShareIcon,
                platforms: [
                    {
                        name: 'Facebook',
                        link: shareFacebookSelvans,
                        icon: <FacebookIcon size={size}/>
                    },
                    {
                        name: 'WhatsApp',
                        link: shareOnWhatsAppSelvans,
                        icon: <WhatsAppIcon size={size}/>
                    },
                    {
                        name: dictionary.copyLink,
                        linkToCopy: selvansWebsite,
                        icon: <ContentCopyIcon sx={{fontSize: size}}/>
                    },
                    {
                        name: 'Twitter',
                        link: shareTwitterSelvans,
                        icon: <TwitterIcon size={size}/>
                    },
                ]
            },
        ]
    ), [dictionary.copyLink, dictionary.follow, dictionary.listen, dictionary.share, shareOnWhatsAppSelvans]);
};

