import {useEffect, useState} from "react";

export const SelvansLogo = ({ uniqueId, size, outerSquare, diamond, letterS, letterM, onlyOneColorNoSquare, oneColor, pulseAnimation }) => {

    const [animationPaused, setAnimationPaused] = useState(false);

    const styles = {
        cls1: {
            fill:  oneColor ? oneColor : outerSquare ? outerSquare : onlyOneColorNoSquare ? 'none' : '#c4da9d',
        },
        cls2: { fill: oneColor ? oneColor : onlyOneColorNoSquare ? onlyOneColorNoSquare : diamond ? diamond : '#717172' },
        cls3: { fill: oneColor ? oneColor : onlyOneColorNoSquare ? onlyOneColorNoSquare : letterM ? letterM : '#292b2b' },
        cls4: { fill: oneColor ? oneColor : onlyOneColorNoSquare ? onlyOneColorNoSquare : letterS ? letterS : '#80ab3f' },
    };

    const animationColor = oneColor ? oneColor : outerSquare ? outerSquare : onlyOneColorNoSquare ? 'none' : '#c4da9d';

    useEffect(() => {
        const handleVisibilityChange = () => {
            if (document.hidden) {
                setAnimationPaused(true);
            } else {
                setAnimationPaused(false);
            }
        };

        document.addEventListener("visibilitychange", handleVisibilityChange);
        return () => {
            document.removeEventListener("visibilitychange", handleVisibilityChange);
        };
    }, []);

    const uniqueKeyframesName = `unique-keyframes-${uniqueId}`;

    useEffect(() => {
        const keyframeStyles = `
            @keyframes fadeInOut-${uniqueId} {
                0%, 25%, 100% {
                    fill: transparent;
                }
                50% {
                    fill: ${animationColor};
                }
            }

            @keyframes fadeOut-${uniqueId} {
                0%, 50%, 100% {
                    fill: ${animationColor};
                }
                25% {
                    fill: transparent;
                }
            }

            .${uniqueId} {
                animation: ${animationPaused ? `fadeOut-${uniqueId}` : `fadeInOut-${uniqueId}`} 4s ease infinite;
            }
        `;
        const normalFillStyle = `
            .${uniqueId} {
                fill: ${animationColor};
            }
        `;

        // Create a style tag for the unique keyframes
        const styleTag = document.createElement("style");
        styleTag.setAttribute("id", uniqueKeyframesName);
        styleTag.innerHTML = pulseAnimation === true ? keyframeStyles : normalFillStyle;

        // Append the style tag to the head
        document.head.appendChild(styleTag);

        // Cleanup function to remove the style tag when the component unmounts
        return () => {
            const existingStyleTag = document.getElementById(uniqueKeyframesName);
            if (existingStyleTag) {
                document.head.removeChild(existingStyleTag);
            }
        };
    }, [uniqueId, animationColor, animationPaused]);

    const containerStyle = {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
    };

    return (
        <div style={containerStyle}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128.74 128.88" width={size} height={size}>
                <g id="Layer_2" data-name="Layer 2">
                    <g id="Layer_1-2" data-name="Layer 1">
                        { (pulseAnimation === true || oneColor || outerSquare) && (
                        <>
                            <path className={uniqueId}
                                  d="M105.1,91.42a10.4,10.4,0,0,1-3.35,2.25v7.93h-8a10.49,10.49,0,0,1-2.33,3.48L86.54,110h23.63V86.34Z"/>
                            <path className={uniqueId}
                                  d="M37.73,105.05a10.5,10.5,0,0,1-2.3-3.48h-8v-8A10.42,10.42,0,0,1,24,91.31l-4.95-5V110H42.68Z"/>
                            <path className={uniqueId}
                                  d="M64.6,101.57l-4.33-4.33a8.4,8.4,0,0,0-11.88,0l16.22,16.22L80.68,97.39a8.43,8.43,0,0,0-11.91,0Z"/>
                            <path className={uniqueId}
                                  d="M48.39,31.59h0a8.39,8.39,0,0,0,11.87,0l4.34-4.33,4.17,4.18a8.42,8.42,0,0,0,11.91,0L64.61,15.37Z"/>
                            <path className={uniqueId}
                                  d="M91.34,23.9a10.41,10.41,0,0,1,2.26,3.37h8.16v8.15a10.5,10.5,0,0,1,3.39,2.28l5,5V18.87H86.3Z"/>
                            <path className={uniqueId}
                                  d="M24,37.81a10.43,10.43,0,0,1,3.48-2.31V27.29H35.6a10.48,10.48,0,0,1,2.24-3.35l5.08-5.07H19V42.73Z"/>
                        </>
                        )}
                        <path style={styles.cls2}
                              d="M89.93,91.61h0L64.6,117,39.25,91.64a8.4,8.4,0,0,0,0,11.88l25.36,25.36,25.32-25.32a8.45,8.45,0,0,0,0-12"/>
                        <path style={styles.cls3} d="M46.9,84,41,89.91a8.17,8.17,0,0,0,11.55,0l.16-.17Z"/>
                        <path style={styles.cls3}
                              d="M91.48,39.06h0L116.85,64.4,91.51,89.74a8.4,8.4,0,0,0,11.88,0l25.35-25.35L103.41,39.06a8.43,8.43,0,0,0-11.93,0"/>
                        <path style={styles.cls3}
                              d="M82.48,48.06,72.09,58.45,52.71,39.07a8.45,8.45,0,0,0-12,0L15.44,64.39l16.09,16.1,5.94-5.94L27.33,64.41,46.72,45,66.19,64.47a8.35,8.35,0,0,0,11.81,0L88.45,54Z"/>
                        <path style={styles.cls4}
                              d="M62.89,64.55l-6.18-6.18L31.53,83.58,12.35,64.41,37.7,39.07a8.74,8.74,0,0,0-12.37,0L0,64.4,25.34,89.74a8.74,8.74,0,0,0,12.34,0Z"/>
                        <path style={styles.cls4}
                              d="M82.22,32.89,65.94,49.15l6.18,6.17L82.24,45.24l19.17,19.17L82.23,83.6a8.7,8.7,0,0,0,0,12.31l25.33-25.33a8.73,8.73,0,0,0,0-12.35Z"/>
                        <path style={styles.cls2}
                              d="M39.25,37.23h0L64.6,11.89,89.93,37.27a8.45,8.45,0,0,0,0-12L64.61,0,39.26,25.35a8.39,8.39,0,0,0,0,11.88"/>
                    </g>
                </g>
            </svg>
        </div>
    )
}