import React, { useContext, useEffect, useState } from 'react';
import {Grid, styled, Typography} from '@mui/material';
import { YoutubePlayLogo } from "../media/YouTubePlayButton";
import { LayoutContext } from "../contexts/LayoutContext/LayoutContext";
import {MainVideoPlayerVideoData, PhotosHomePage} from "../displayConfig";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';


export const MainVideoPlayer = () => {
    const { isMobile, isTablet } = useContext(LayoutContext);
    const videos = MainVideoPlayerVideoData();

    const [showFloatingWindow, setShowFloatingWindow] = useState(false);
    const [selectedVideo, setSelectedVideo] = useState(null);

    const openFloatingWindowAndPlayVideo = (playlistId) => {
        setSelectedVideo(playlistId);
        setShowFloatingWindow(true);
        document.getElementById('video-iframe').src += '&autoplay=1'
    };

    const handleCloseFloatingWindow = () => {
        setSelectedVideo(null);
        setShowFloatingWindow(false);
    };

    useEffect(() => {
        const closeOnOutsideClick = (event) => {
            if (showFloatingWindow && selectedVideo) {
                const iframe = document.querySelector("#video-iframe");
                if (iframe && !iframe.contains(event.target)) {
                    handleCloseFloatingWindow();
                }
            }
        };

        const closeOnEscKey = (event) => {
            if (showFloatingWindow && selectedVideo && event.key === "Escape") {
                handleCloseFloatingWindow();
            }
        };

        if (showFloatingWindow && selectedVideo) {
            document.addEventListener("mousedown", closeOnOutsideClick);
            document.addEventListener("keydown", closeOnEscKey);
        }

        return () => {
            document.removeEventListener("mousedown", closeOnOutsideClick);
            document.removeEventListener("keydown", closeOnEscKey);
        };
    }, [showFloatingWindow, selectedVideo]);

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{
                width: '100%',
                paddingBottom: isMobile ? 10 : 20,
                paddingTop: isMobile ? 7 : 17,
                backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url(${PhotosHomePage[4].link})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundAttachment: 'fixed',
            }}
        >
            <Grid item large={11} xs={10} sx={{ textAlign: 'center' }}>
                {/*<Typography variant="h3" sx={{ color: '#fff' }}>Videos</Typography>*/}
                <Typography variant={ ( isMobile || isTablet ) ? "h4" : "h2" } sx={{ color: '#fff' }}>Watch & Feel</Typography>
            </Grid>
            <Grid item
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  xl={8}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
            >
                {videos.map((video, i) => (
                    <Grid item key={i} sx={{ paddingTop: 3, marginLeft: (isMobile || isTablet) ? 0 : i % 2 === 0 ? -3 : 3 }}>
                        <Box
                            height={isMobile ? "60vw" : 282}
                            width={isMobile ? "90vw" : 501}
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${video.ytThumbnail})`,
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                ':hover': {
                                    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.0)), url(${video.ytThumbnail})`,
                                },
                            }}
                            onClick={() => openFloatingWindowAndPlayVideo(video.videoId)}
                        >
                            <div style={{ position: 'relative', width: '100%', display: 'flex', justifyContent: 'center' }}>
                                <YoutubePlayLogo width={isMobile ? '30vw' : '18vw'} height={isMobile ? '30vw' : '18vw'} />
                            </div>
                        </Box>
                    </Grid>
                ))}
            </Grid>
            {showFloatingWindow && selectedVideo && (
                <Box
                    sx={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(0, 0, 0, 0.8)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 9999,
                    }}
                >
                    <IconButton
                        sx={{
                            position: 'absolute',
                            top: '10px',
                            right: '10px',
                            color: '#fff',
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        }}
                        onClick={handleCloseFloatingWindow}
                    >
                        <CloseIcon />
                    </IconButton>
                    <iframe
                        id="video-iframe"
                        width={ isMobile ? "100%" : isTablet ? "80%" : "60%"}
                        height={ isMobile ? "50%" : isTablet ? "80%" : "60%"}
                        src={`https://www.youtube.com/embed/${selectedVideo}?autoplay=true&amp;showinfo=false&amp;rel=0&amp;enablejsapi=1&amp;origin=https%3A%2F%2Fdreamtheater.net&amp;widgetid=13`}
                        title="Video"
                        style={{ border: 'none' }}
                        frameBorder="0"
                        allow="autoplay; encrypted-media"
                        allowFullScreen
                    />
                </Box>
            )}
        </Grid>
    );
};