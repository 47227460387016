import * as React from 'react';
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import {styled} from "@mui/material/styles";
import {FormControl, Select} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Flag from 'react-world-flags';
import {useContext, useState} from "react";
import {LanguageContext} from "../contexts/LocalizationContext/languages/LanguageContext";


const StyledFormControl = styled(FormControl)(() => ({
    '&.MuiFormControl-root': {
        padding: 0,
    }
}));

const StyledSelect = styled(Select)(() => ({
    '& .MuiSelect-select': {
        // padding: 0,
        paddingRight: 0,
    }
}));

const StyledIconButton = styled(IconButton)(() => ({
    '&.MuiIconButton-root': {
        padding: 0,
    },
}));

export const SelectLanguageDropDown = () => {

    const { language, setLanguage } = useContext(LanguageContext);

    const [languageSelected, setLanguageSelected] = useState(language);

    const handleChange = (event) => {
        setLanguageSelected(event.target.value);
    };

    const handleClick = (lang) => {
        setLanguage(lang);
    };

    return(
        <Box>
            <StyledFormControl>
                <StyledSelect
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={languageSelected}
                    label="Language"
                    onChange={handleChange}
                    sx={{
                        '& .MuiOutlinedInput-notchedOutline': {
                            border: 'none',
                        },
                        '& .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input': {
                            padding: 0,
                        },
                    }}
                    IconComponent={null}
                    MenuProps={{
                        getContentAnchorEl: null,
                    }}

                >
                    <MenuItem value="en" sx={{ justifyContent: 'center' }}>
                        <StyledIconButton onClick={() => handleClick('en')}>
                            <Flag code="826" height="26" />
                        </StyledIconButton>
                    </MenuItem>
                    <MenuItem value="es" sx={{ justifyContent: 'center' }}>
                        <StyledIconButton onClick={() => handleClick('es')}>
                            <Flag code="Es" height="27" />
                        </StyledIconButton>
                    </MenuItem>
                    <MenuItem value="it" sx={{ justifyContent: 'center' }}>
                        <StyledIconButton onClick={() => handleClick('it')}>
                            <Flag code="It" height="27" />
                        </StyledIconButton>
                    </MenuItem>
                </StyledSelect>
            </StyledFormControl>
        </Box>
    )
}