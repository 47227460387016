import ninasAlbumCover from './media/everything-starts-album-artworks/ninas-sample-album-cover.jpg'

export const animalTendencyFacebook = 'https://www.facebook.com/animaltendencytenerife/'
export const campingInTheSkiesWeb = 'https://campingintheskies.com/'
export const browserTabName = 'MattSelvans'
export const selvansDomain = 'http://selvansweb.web.app'
export const selvansWebsite = 'https://mattselvans.com/'
export const shareOnWhatsAppThe = 'whatsapp://send?text='
export const albumEveythingStartsBandcamp = 'https://mattselvans.bandcamp.com/album/everything-starts'
export const buyEverthingStartsBandcamp = 'https://mattselvans.bandcamp.com/album/everything-starts?action=buy&from=embed'
export const youTubeMusicSelvans = 'https://music.youtube.com/watch?v=AntJkkzsNGE&list=OLAK5uy_lnNazGIiBBi5VtTPCmmQa3KlGoWhYlZdk'
export const amazonMusicSelvans = 'https://music.amazon.com/search/matt+selvans?filter=IsLibrary%7Cfalse&sc=none'
export const appleMusicSelvans = 'https://music.apple.com/us/album/stuck/1659063608?i=1659063609'
export const spotifySelvans = 'https://open.spotify.com/album/0KG3lfy6637RUCsQxdxB6N'
export const followOnWhatsAppSelvans = 'https://api.whatsapp.com/send/?phone=%2B447763348700&text=Hi+Matt+please+notify+me+when+you+have+new+content+released&type=phone_number&app_absent=0'
export const followOnFacebookSelvans = 'https://www.facebook.com/MattSelvans/'
export const followOnInstagramSelvans = 'https://www.instagram.com/mattselvans/'
export const followOnYouTubeSelvans = 'https://www.youtube.com/@mattselvans?sub_confirmation=1'
export const shareFacebookSelvans = 'https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fmattselvans.com%2F&src=sdkpreparse'
export const shareTwitterSelvans = 'https://twitter.com/Matt_Selvans/status/1602087412963975168?s=20&t=v6pNGGzH1Y-xqUKDgoRm6g'
export const donatePaypalMattSelvans = 'https://www.paypal.com/donate/?hosted_button_id=B2TEVJ54JCBJ6'
export const selvansEmailAddress = 'mattselvans@gmail.com'
export const selvansPhoneNumber = '+44 7763 348700'
export const unicornsIconsCredit = 'https://iconscout.com/'
export const everythingStartsOfficialCover = 'https://f4.bcbits.com/img/a4025878033_10.jpg'

export const routes = {
    discography: "/discography",
    stories: '/stories',
    partners: '/partners',
    aboutMatt: '/about-matt-selvans',
    homepage: "/",
}

export const homepageMeta = {
    url: routes.homepage,
    title: "Home",
    description: "Matt Selvans homepage",
    keywords: [],
    thumbnail: "https://mattselvans.com/wp-content/uploads/2023/05/Amnesia-ThumbnailOG1200-x-630-whatsapp-less-300kb.jpg",
}
export const discographyMeta = {
    url: routes.discography,
    title: "Discography",
    description: "'All Matt Selvans\' releases'",
    keywords: [],
    thumbnail: "https://campingintheskies.com/wp-content/uploads/2023/05/DsbyOg222.jpg",
}