import * as React from "react";
import {useContext} from "react";
import {LanguageContext} from "../contexts/LocalizationContext/languages/LanguageContext";
import {Container, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import otroSoloPerformance from '../media/PhotosHomepage/05-otro-solo-performance.jpg';
import {LayoutContext} from "../contexts/LayoutContext/LayoutContext";
import SelvansStudioAtIYD from "../media/OG-Images/Selvans-Studio-At-IYD.jpg";
import {Helmet} from "react-helmet";

export const AboutMattSelvans =() => {

    const { isMobile } = useContext(LayoutContext);
    const { dictionary } = useContext(LanguageContext);

    return(
       <Container maxWidth="lg">
           <Helmet>
               <title>About Matt Selvans</title>
               <meta
                   name='description'
                   content="About the amazing artist Matt Selvans"
               />
               <meta name='keywords' content="Matt Selvans About Us, Musical Evolution, Genre Diversity, Favorite Songs, Fan Connections, Upcoming Projects, Musical Challenges, Travel-Inspired Music, Family Influence, Artistic Growth" />
               <meta property="og:image" content={otroSoloPerformance} />
           </Helmet>
           <Box height="auto" width="100%" padding="40px 0px"
                sx={{
                    width: '100%',
                    paddingBottom: isMobile ? 10 : 20,
                    paddingTop: isMobile ? 7 : 17,
                    backgroundImage: `url(${otroSoloPerformance})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundAttachment: 'fixed',
                }}
           >
              <Box bgcolor={'rgba(255,255,255,0.8)'} color={'#000000'} margin="0px 20px" padding="20px 0px" borderRadius={1}>
                  <Typography textAlign="center" variant="h4" color="inherit">Welcome to Matt Selvan's Musical World</Typography>
                  <Typography sx={{ whiteSpace: 'pre-line', padding: "0px 10px" }} dangerouslySetInnerHTML={{ __html: dictionary.aboutMattSelvans }} />
              </Box>
           </Box>
       </Container>
    )
}